export const handleStreamingResponse = async (filePath, onChunkReceived, onError, onComplete) => {
  const LAMBDA_URLS = {
    PDF: 'https://nzhwket7qwmx2773wajdi7nfum0nsazj.lambda-url.us-east-1.on.aws/',
    AI_MAGIC: 'https://pfdtkc2ylh2a7bvjspj2ictzgy0bjgvk.lambda-url.us-east-1.on.aws/',
    TEXT_INPUT: 'https://ssxsfkvjiilboeeeij76b6es3u0cezcb.lambda-url.us-east-1.on.aws/',
  };

  const validateMermaidSyntax = async (content) => {
    try {
      if (!window.mermaid) {
        console.warn('Mermaid not loaded, skipping validation');
        return true;
      }
      await window.mermaid.parse(content);
      return true;
    } catch (error) {
      return {
        isValid: false,
        error: error.message,
      };
    }
  };

  const getLambdaUrl = (path) => {
    if (path.endsWith('.pdf')) {
      return LAMBDA_URLS.PDF;
    } else if (path.includes('ai_magic.txt')) {
      return LAMBDA_URLS.AI_MAGIC;
    } else if (path.includes('text_input.txt')) {
      return LAMBDA_URLS.TEXT_INPUT;
    }
    return LAMBDA_URLS.PDF;
  };

  const lambdaUrl = getLambdaUrl(filePath);
  let accumulatedContent = '';

  try {
    const response = await fetch(lambdaUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Origin': window.location.origin,
      },
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify({
        fileName: filePath,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let buffer = '';

    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        if (buffer) {
          accumulatedContent += buffer;
          const validationResult = await validateMermaidSyntax(accumulatedContent);

          if (validationResult === true || validationResult.isValid) {
            onChunkReceived(buffer);
          } else {
            onError(new Error(`Mermaid syntax error: ${validationResult.error}`));
          }
        }

        if (onComplete) {
          onComplete(); // Notify that streaming is complete
        }

        break;
      }

      const chunkText = decoder.decode(value, { stream: true });
      buffer += chunkText;

      const lastNewlineIndex = buffer.lastIndexOf('\n');
      if (lastNewlineIndex !== -1) {
        const completeContent = buffer.substring(0, lastNewlineIndex + 1);
        buffer = buffer.substring(lastNewlineIndex + 1);

        accumulatedContent += completeContent;

        const validationResult = await validateMermaidSyntax(accumulatedContent);

        if (validationResult === true || validationResult.isValid) {
          onChunkReceived(completeContent);
        } else {
          onError(new Error(`Mermaid syntax error: ${validationResult.error}`));
          break;
        }
      }
    }
  } catch (error) {
    onError(error);
  }
};