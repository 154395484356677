import { replaceFontAwesomeIconsWithInlineSVGs } from "./changeIconsHelper";
import html2canvas from 'html2canvas';

const saveAsPNG = async (isPaidUser) => {
    let mermaidContainer = document.getElementById("mermaid-chart");
    if (!mermaidContainer) {
        throw new Error("No Mermaid container element found");
    }

    // Get the original dimensions
    const originalWidth = mermaidContainer.offsetWidth;
    const originalHeight = mermaidContainer.offsetHeight;

    // Set scale factor for higher resolution (2x or 3x)
    const scaleFactor = 3;

    mermaidContainer = await replaceFontAwesomeIconsWithInlineSVGs(mermaidContainer);
    const clonedMermaidContainer = mermaidContainer.cloneNode(true);

    // Apply watermark for non-paid users
    if (!isPaidUser) {
        const base64Mark = btoa(markTemplate("map-this.com"));
        clonedMermaidContainer.style.backgroundImage = `url('data:image/svg+xml;base64,${base64Mark}')`;
        clonedMermaidContainer.style.backgroundRepeat = "repeat";
    }

    // Set explicit dimensions
    clonedMermaidContainer.style.width = `${originalWidth}px`;
    clonedMermaidContainer.style.height = `${originalHeight}px`;
    
    // Ensure the container is visible but not affecting layout
    clonedMermaidContainer.style.position = "fixed";
    clonedMermaidContainer.style.left = "-9999px";
    clonedMermaidContainer.style.top = "-9999px";
    
    document.body.appendChild(clonedMermaidContainer);

    try {
        const canvas = await html2canvas(clonedMermaidContainer, {
            logging: false,
            scale: scaleFactor, // Increase resolution
            useCORS: true,
            letterRendering: true,
            allowTaint: true,
            backgroundColor: "#ffffff",
            imageTimeout: 0,
            width: originalWidth,
            height: originalHeight,
        });

        // Create high-quality PNG
        const png = canvas.toDataURL("image/png", 1.0);
        
        // Download the file
        const a = document.createElement("a");
        a.download = "mindmap.png";
        a.href = png;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        
        // Cleanup
        document.body.removeChild(a);
    } catch (error) {
        console.error("Error generating PNG file:", error);
        throw error;
    } finally {
        document.body.removeChild(clonedMermaidContainer);
    }
};

const markTemplate = (name) => {
    // Scale watermark size according to the higher resolution
    const width = 300; // Increased from 100
    const height = 300; // Increased from 100
    const spaceBetweenRepeats = 150; // Increased from 50
    
    return `<svg xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}'>
              <defs>
                <pattern id='watermark' patternUnits='userSpaceOnUse' 
                        width='${width + spaceBetweenRepeats}' 
                        height='${height + spaceBetweenRepeats}'>
                  <text x='30' y='${height / 2}' 
                        fill='rgba(0,0,0,0.1)' 
                        font-size='42' 
                        font-family='Arial, sans-serif'>
                    ${name}
                  </text>
                </pattern>
              </defs>
              <rect width='100%' height='100%' fill='url(#watermark)' />
            </svg>`;
};

export { saveAsPNG };