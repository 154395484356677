import { copy, remove } from 'aws-amplify/storage';
import { cacheService } from '../ui-components/cacheService';

const parseFileKey = (fileKey) => {
    let fileType = '';
    let uuid = null;
    let cleanName = '';

    if (fileKey.endsWith('.pdf')) {
        fileType = '.pdf';
        uuid = fileKey.substring(0, 36);
        cleanName = fileKey.substring(37);
    } else if (fileKey.includes('_ai_magic.txt')) {
        fileType = '_ai_magic.txt';
        cleanName = fileKey.replace(/^(AI_Magic\/|TextInput\/)/, '');
        uuid = cleanName.substring(0, 36);
        cleanName = cleanName.substring(37);
    } else if (fileKey.includes('_text_input.txt')) {
        fileType = '_text_input.txt';
        cleanName = fileKey.replace(/^(AI_Magic\/|TextInput\/)/, '');
        uuid = cleanName.substring(0, 36);
        cleanName = cleanName.substring(37);
    }

    let namePart = cleanName.replace(new RegExp(`${fileType}$`), '');
    return { uuid, namePart, fileType };
};

export const handleFileRename = async (oldKey, newName, onSuccess = () => {}, onError = () => {}) => {
    const { uuid, fileType } = parseFileKey(oldKey);
    const type = fileType.includes('_ai_magic') ? 'AI_Magic' : 
                fileType.includes('_text_input') ? 'TextInput' : 'pdfs';
    
    let newKey;
    if (fileType === '.pdf') {
        newKey = `${uuid}-${newName}.pdf`;
    } else {
        newKey = `${type}/${uuid}_${newName}${fileType}`;
    }

    const newSummaryKey = newKey.replace(/(\.pdf|\.txt)$/, '_summary.txt');
    const oldSummaryKey = oldKey.replace(/(\.pdf|\.txt)$/, '_summary.txt');

    try {
        // First copy the main file
        await copy({
            source: { key: oldKey, accessLevel: 'private' },
            destination: { key: newKey, accessLevel: 'private' }
        });

        // Try to copy the summary file if it exists
        try {
            await copy({
                source: { key: oldSummaryKey, accessLevel: 'private' },
                destination: { key: newSummaryKey, accessLevel: 'private' }
            });
        } catch (error) {
            console.warn(`Summary file copy issue: ${error.message}`);
        }

        // Delete the old files after successful copy
        await remove({ key: oldKey, options: { accessLevel: 'private' } });
        try {
            await remove({ key: oldSummaryKey, options: { accessLevel: 'private' } });
        } catch (error) {
            console.warn(`Summary file deletion issue: ${error.message}`);
        }

        // Clear cache to ensure fresh data
        cacheService.clearCache();

        // Call success callback with the new key
        onSuccess(newKey);
        
        return { success: true, newKey };
    } catch (error) {
        console.error(`Error renaming file: ${error.message}`);
        onError(error);
        return { success: false, error };
    }
};