import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { 
  CssBaseline, 
  Grid, 
  Typography, 
  Button, 
  ToggleButtonGroup, 
  ToggleButton,
  Box,
  Alert,
  Chip,
  Snackbar
} from '@mui/material';
import { Copy, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import PricingCard from '../ui-components/PricingCard';
import RupeePricingCard from '../ui-components/RupeePricingCard';
import { handleUpgradeClick } from '../util/HandleUpgrade';
import LoadingOffer from '../ui-components/LoadingOffer';

const theme = createTheme({
  palette: {
    primary: { main: '#000000' },
    background: { default: '#f4f4f4' },
  },
  typography: {
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1rem',
    }
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          boxShadow: 'none',
          padding: '20px',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          padding: '10px 25px',
          fontSize: '1rem',
          textTransform: 'none',
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            color: '#edd1d3',
            backgroundColor: 'black',
            fontWeight: 600
          },
          '&:hover': {
            backgroundColor: '#eeeeee',
          },
        }
      }
    }
  }
});

const UpgradeComponent = ({userDetails}) => {
  const [billingCycle, setBillingCycle] = useState('yearly');
  const [loading, setLoading] = useState(false);
  const [isIndianUser, setIsIndianUser] = useState(false);
  const [showRupeePricing, setShowRupeePricing] = useState(true);
  const [membershipPlan, setMembershipPlan] = useState('Freemium');
  const [userEmail, setUserEmail] = useState('');
  const [specialOffer, setSpecialOffer] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(3600); // 1 hour in seconds
  const [isLoadingOffer, setIsLoadingOffer] = useState(true);
  const [promoMessage, setPromoMessage] = useState('');
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const plans = [
    {
      name: 'Free',
      priceMonthly: 0,
      priceYearly: 0,
      discountMonthly: 0,
      features: ['10 credits/month (~2 mindmaps)', "Text or AI Prompt", 'Export with watermark'],
      limitations: ['No PDF uploads', 'Export without watermark'],
      cardColor: '#ffffff'
    },
    {
      name: 'Pro',
      priceMonthly: 5.99,
      priceYearly: 4.16,
      discountMonthly: 1.0,
      features: ['250 credits/month (~50 mindmaps)', "PDF upload is included", 'Export without watermark', 'No limit on file size', 'Priority support by email'],
      limitations: [],
      cardColor: '#edd1d3'
    }
  ];

  const indianPlans = [
    {
      name: 'Free',
      priceMonthly: 0,
      priceYearly: 0,
      discountMonthly: 0,
      features: ['10 credits/month (~2 mindmaps)', "Text or AI Prompt", 'Export with watermark'],
      limitations: ['No PDF uploads', 'Export without watermark'],
      cardColor: '#ffffff'
    },
    {
      name: 'Pro',
      priceMonthly: 499,
      priceYearly: 349,
      discountMonthly: 1.0,
      features: ['250 credits/month (~50 mindmaps)', "PDF upload is included", 'Export without watermark', 'No limit on file size', 'Priority support by email'],
      limitations: [],
      cardColor: '#edd1d3'
    }
  ];
  useEffect(() => {
    const fetchSpecialOffer = async () => {
      try {
        // Extract and validate all required fields
        const userData = {
          userID: userDetails?.userID || null,
          email: userDetails?.Email || null, // Matches the case in your logs
          name: userDetails?.Name || null,   // Matches the case in your logs
          roleType: userDetails?.userOnboardingData?.roleType || "Unknown",
          findUs: userDetails?.userOnboardingData?.findUs || "Unknown",
        };
  
        // Validate required fields
        if (!userData.userID || !userData.email || !userData.name) {
          console.warn('Missing required user data fields:', {
            userID: !!userData.userID,
            email: !!userData.email,
            name: !!userData.name
          });
          return;
        }
  
        const response = await fetch('https://86z7rpqa2g.execute-api.us-east-1.amazonaws.com/dev', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        
        // Parse the response body if it's a string
        const parsedData = typeof data.body === 'string' ? JSON.parse(data.body) : data;
  
        if (parsedData.error) {
          return;
        }
  
        if (parsedData.couponCode && parsedData.expiresAt) {
          setSpecialOffer(parsedData.couponCode);
          setPromoMessage(parsedData.message || '');
  
          // Calculate remaining time in seconds using expiresAt
          const expiryTime = new Date(parsedData.expiresAt).getTime(); // Convert to milliseconds
          const currentTime = Date.now(); // Current time in milliseconds
          const timeLeftInSeconds = Math.max(0, Math.floor((expiryTime - currentTime) / 1000)); // Ensure non-negative
  
          setTimeRemaining(timeLeftInSeconds);
        } else {
          console.warn('Missing coupon code or expiry time in response:', parsedData);
        }
      } catch (error) {
        console.error('Error in fetchSpecialOffer:', error);
      } finally {     
        setIsLoadingOffer(false);
      }
    };
  
    // Only call if userDetails has required data
    if (userDetails && userDetails.Email && userDetails.Name) {
      fetchSpecialOffer();
    }
  }, [userDetails]);
  
  
  const handleCopyCode = () => {
    if (specialOffer) {
      navigator.clipboard.writeText(specialOffer);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };


  // Timer countdown effect
  useEffect(() => {
    if (!specialOffer || timeRemaining <= 0) return;

    const timer = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setSpecialOffer(null);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [specialOffer, timeRemaining]);

  // Location detection effect
  useEffect(() => {
    const detectLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setIsIndianUser(data.country === 'IN');
      } catch (error) {
        //console.error('Error detecting location:', error);
      }
    };
    detectLocation();
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleBillingChange = (event, newBillingCycle) => {
    if (newBillingCycle !== null) {
      setBillingCycle(newBillingCycle);
    }
  };

  const handleUpgrade = async ({ planName, couponCode }) => {
    setLoading(true);
    try {
      const finalCouponCode = specialOffer || couponCode;
      
      if (planName === 'Pro' && billingCycle === 'monthly' && !isIndianUser) {
        await handleUpgradeClick("$6", finalCouponCode);
      } else if (planName === 'Pro' && billingCycle === 'yearly' && !isIndianUser) {
        await handleUpgradeClick("AnnualPro", finalCouponCode);
      } else if (planName === 'Pro' && billingCycle === 'monthly' && isIndianUser) {
        await handleUpgradeClick("MonthlyINR499", finalCouponCode);
      } else if (planName === 'Pro' && billingCycle === 'yearly' && isIndianUser) {
        await handleUpgradeClick("AnnualINR4199", finalCouponCode);
      }
      navigate('/app');
    } finally {
      setLoading(false);
    }
  };

  const handleSkip = () => {
    navigate('/app');
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ padding: 20, maxWidth: 800, margin: '0 auto' }}>
        <Button
          variant="text"
          onClick={handleSkip}
          sx={{ 
            color: 'text.secondary',
            position: 'absolute',
            right: 20,
            top: 20,
          }}
        >
          Skip for now
        </Button>

        <Typography variant="h4" align="center" style={{ padding: '20px' }}>
          Upgrade to Pro
        </Typography>

        {isLoadingOffer ? (
  <Box sx={{ mb: 4 }}>
    <LoadingOffer />
  </Box>
) : specialOffer && (
    <Box sx={{ mb: 4 }}>
      <Alert 
        severity="success"
        sx={{
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& .MuiAlert-message': {
            width: '100%'
          }
        }}
      >
        <Typography variant="h6" sx={{ mb: 1, textAlign: 'center' }}>
          Special Welcome Offer, {userDetails?.Name?.split(' ')[0]}!
        </Typography>
        {promoMessage && (
          <Typography sx={{ mb: 2, textAlign: 'center' }}>
            {promoMessage}
          </Typography>
        )}
        <Typography sx={{ mb: 2, textAlign: 'center' }}>
          Use code:{' '}
          <Chip 
            label={specialOffer}
            onClick={handleCopyCode}
            onDelete={handleCopyCode}
            deleteIcon={copied ? <Check size={16} /> : <Copy size={16} />}
            sx={{ 
              cursor: 'pointer',
              ml: 1
            }}
          />
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
          Offer expires in: {formatTime(timeRemaining)}
        </Typography>
      </Alert>
    </Box>
  )}


        <div style={{ textAlign: 'center', paddingBottom: '40px' }}>
          <ToggleButtonGroup
            color="primary"
            value={billingCycle}
            exclusive
            onChange={handleBillingChange}
            aria-label="Billing cycle"
          >
            <ToggleButton sx={{ textTransform: 'none' }} value="monthly">Monthly</ToggleButton>
            <ToggleButton sx={{ textTransform: 'none' }} value="yearly">
              Yearly
              <Typography
                component="span"
                variant="caption"
                sx={{ ml: 1, color: 'success.main' }}
              >
                Save 30%
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        {isIndianUser && (
          <div style={{ 
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            marginBottom: '16px',
            fontSize: '0.875rem',
            color: '#666'
          }}>
            <div style={{ 
              background: '#f5f5f5', 
              borderRadius: '20px',
              padding: '4px',
              display: 'inline-flex',
              alignItems: 'center'
            }}>
              <button
                onClick={() => setShowRupeePricing(true)}
                style={{
                  background: showRupeePricing ? '#fff' : 'transparent',
                  border: 'none',
                  padding: '4px 12px',
                  borderRadius: '16px',
                  cursor: 'pointer',
                  fontWeight: showRupeePricing ? '600' : '400',
                  color: showRupeePricing ? '#000' : '#666',
                  transition: 'all 0.2s ease',
                  boxShadow: showRupeePricing ? '0 1px 3px rgba(0,0,0,0.1)' : 'none'
                }}
              >
                ₹ INR
              </button>
              <button
                onClick={() => setShowRupeePricing(false)}
                style={{
                  background: !showRupeePricing ? '#fff' : 'transparent',
                  border: 'none',
                  padding: '4px 12px',
                  borderRadius: '16px',
                  cursor: 'pointer',
                  fontWeight: !showRupeePricing ? '600' : '400',
                  color: !showRupeePricing ? '#000' : '#666',
                  transition: 'all 0.2s ease',
                  boxShadow: !showRupeePricing ? '0 1px 3px rgba(0,0,0,0.1)' : 'none'
                }}
              >
                $ USD
              </button>
            </div>
          </div>
        )}

        <Grid container spacing={2} justifyContent="center" style={{ paddingBottom: '40px'}}>
          {[0, 1].map((index) => (
            <Grid item xs={12} sm={6} key={index}>
              {isIndianUser ? (
                showRupeePricing ? (
                  <RupeePricingCard 
                    plan={indianPlans[index]} 
                    billingCycle={billingCycle}
                    membershipPlan={membershipPlan}
                    isLoggedIn={true}
                    onUpgradeClick={handleUpgrade}
                    loading={loading}
                    couponCode=""
                  />
                ) : (
                  <PricingCard
                    plan={plans[index]}
                    billingCycle={billingCycle}
                    membershipPlan={membershipPlan}
                    isLoggedIn={true}
                    onUpgradeClick={handleUpgrade}
                    loading={loading}
                    couponCode=""
                  />
                )
              ) : (
                <PricingCard
                  plan={plans[index]}
                  billingCycle={billingCycle}
                  membershipPlan={membershipPlan}
                  isLoggedIn={true}
                  onUpgradeClick={handleUpgrade}
                  loading={loading}
                  couponCode=""
                />
              )}
            </Grid>
          ))}
        </Grid>

        <Button
          variant="text"
          onClick={handleSkip}
          sx={{ 
            color: 'text.secondary',
            margin: '0 auto',
            display: 'block',
            mb: 4
          }}
        >
          Skip for now
        </Button>

        <Typography 
          variant="body2" 
          align="center" 
          color="text.secondary"
          sx={{ pb: 2 }}
        >
          Secure payment powered by Stripe. Cancel anytime.
        </Typography>
      </div>
      <Snackbar
        open={copied}
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
        message="Coupon code copied!"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </ThemeProvider>
  );
};

export default UpgradeComponent;