import React, { useState, useEffect} from 'react';
import { Heart, Gift, Sparkles, Sun, Snowflake, Leaf, PartyPopper, Star, ShoppingCart, Zap, Award, Calendar } from 'lucide-react';
import ReactConfetti from 'react-confetti';
import './SaleBanner.css';

const SALE_PHASES = {
  HOLIDAY_SPECIAL: {
    startDate: new Date("December 6, 2024 00:00:00").getTime(),
    endDate: new Date("December 24, 2024 23:59:59").getTime(),
    title: "Plan Your Year Ahead",
    discount: "39%",
    code: "PLAN2025",
    badge: "Holiday Planning Special",
    icon: Gift,
    theme: {
      background: "linear-gradient(135deg, #1c4432 0%, #0f3443 100%)",
      badgeGradient: "linear-gradient(135deg, #e63946 0%, #c1121f 100%)",
      highlight: "#fca311"
    }
  },
  YEAR_END: {
    startDate: new Date("December 25, 2024 00:00:00").getTime(),
    endDate: new Date("December 31, 2024 23:59:59").getTime(),
    title: "New Year Planning Kit",
    discount: "45%",
    code: "VISION2025",
    badge: "Vision Board Special",
    icon: Sparkles,
    theme: {
      background: "linear-gradient(135deg, #2c3e50 0%, #3d5a80 100%)",
      badgeGradient: "linear-gradient(135deg, #f72585 0%, #b5179e 100%)",
      highlight: "#48cae4"
    }
  },
  NEW_YEAR_2025: {
    startDate: new Date("January 1, 2025 00:00:00").getTime(),
    endDate: new Date("January 14, 2025 23:59:59").getTime(),
    title: "Fresh Start Bundle",
    discount: "50%",
    code: "FRESH2025",
    badge: "New Year Creativity",
    icon: Star,
    theme: {
      background: "linear-gradient(135deg, #023047 0%, #126782 100%)",
      badgeGradient: "linear-gradient(135deg, #ffd700 0%, #fb8500 100%)",
      highlight: "#ffd700"
    }
  },
  WINTER_FOCUS: {
    startDate: new Date("January 15, 2025 00:00:00").getTime(),
    endDate: new Date("February 28, 2025 23:59:59").getTime(),
    title: "Winter Focus Pack",
    discount: "29%",
    code: "FOCUS29",
    badge: "Productivity Boost",
    icon: Snowflake,
    theme: {
      background: "linear-gradient(135deg, #184e77 0%, #1e6091 100%)",
      badgeGradient: "linear-gradient(135deg, #90e0ef 0%, #00b4d8 100%)",
      highlight: "#caf0f8"
    }
  },
  STUDENT_SPRING: {
    startDate: new Date("March 1, 2025 00:00:00").getTime(),
    endDate: new Date("May 31, 2025 23:59:59").getTime(),
    title: "Spring Study Bundle",
    discount: "35%",
    code: "STUDY35",
    badge: "Student Success",
    icon: Sun,
    theme: {
      background: "linear-gradient(135deg, #386641 0%, #4c956c 100%)",
      badgeGradient: "linear-gradient(135deg, #b7e4c7 0%, #95d5b2 100%)",
      highlight: "#d8f3dc"
    }
  },
  SUMMER_CREATIVITY: {
    startDate: new Date("June 1, 2025 00:00:00").getTime(),
    endDate: new Date("July 31, 2025 23:59:59").getTime(),
    title: "Summer of Ideas",
    discount: "33%",
    code: "CREATE33",
    badge: "Creativity Unleashed",
    icon: Sun,
    theme: {
      background: "linear-gradient(135deg, #ff7b00 0%, #ff9e00 100%)",
      badgeGradient: "linear-gradient(135deg, #ffbe0b 0%, #fb8500 100%)",
      highlight: "#ffffff"
    }
  },
  BACK_TO_SCHOOL: {
    startDate: new Date("August 1, 2025 00:00:00").getTime(),
    endDate: new Date("September 30, 2025 23:59:59").getTime(),
    title: "Academic Success Kit",
    discount: "39%",
    code: "ACADEMIC39",
    badge: "Student & Teacher Special",
    icon: Calendar,
    theme: {
      background: "linear-gradient(135deg, #2b2d42 0%, #3d405b 100%)",
      badgeGradient: "linear-gradient(135deg, #f2cc8f 0%, #e07a5f 100%)",
      highlight: "#f4f1de"
    }
  },
  FALL_FOCUS: {
    startDate: new Date("October 1, 2025 00:00:00").getTime(),
    endDate: new Date("November 20, 2025 23:59:59").getTime(),
    title: "Fall Productivity Pack",
    discount: "31%",
    code: "FALL31",
    badge: "Autumn Special",
    icon: Leaf,
    theme: {
      background: "linear-gradient(135deg, #594a3c 0%, #6f5e53 100%)",
      badgeGradient: "linear-gradient(135deg, #d4a373 0%, #e3b7a0 100%)",
      highlight: "#faedcd"
    }
  },
  BLACK_FRIDAY: {
    startDate: new Date("November 21, 2025 00:00:00").getTime(),
    endDate: new Date("November 30, 2025 23:59:59").getTime(),
    title: "Ultimate Mind Map Suite",
    discount: "50%",
    code: "MINDMAP50",
    badge: "Biggest Bundle",
    icon: Star,
    theme: {
      background: "linear-gradient(135deg, #0a0908 0%, #22223b 100%)",
      badgeGradient: "linear-gradient(135deg, #f72585 0%, #7209b7 100%)",
      highlight: "#4cc9f0"
    }
  },
  CYBER_WEEK: {
    startDate: new Date("December 1, 2025 00:00:00").getTime(),
    endDate: new Date("December 9, 2025 23:59:59").getTime(),
    title: "Digital Brainstorm Pack",
    discount: "47%",
    code: "BRAIN47",
    badge: "Digital Special",
    icon: Zap,
    theme: {
      background: "linear-gradient(135deg, #240046 0%, #3c096c 100%)",
      badgeGradient: "linear-gradient(135deg, #4cc9f0 0%, #4895ef 100%)",
      highlight: "#a2d2ff"
    }
  },
  HOLIDAY_SPECIAL_2025: {
    startDate: new Date("December 10, 2025 00:00:00").getTime(),
    endDate: new Date("December 24, 2025 23:59:59").getTime(),
    title: "2026 Planning Bundle",
    discount: "39%",
    code: "PLAN2026",
    badge: "Early Planning Special",
    icon: Gift,
    theme: {
      background: "linear-gradient(135deg, #2d3047 0%, #1b3a4b 100%)",
      badgeGradient: "linear-gradient(135deg, #ff006e 0%, #fb5607 100%)",
      highlight: "#ffbe0b"
    }
  },
  YEAR_END_2025: {
    startDate: new Date("December 25, 2025 00:00:00").getTime(),
    endDate: new Date("December 31, 2025 23:59:59").getTime(),
    title: "Vision Board Kit 2026",
    discount: "45%",
    code: "VISION2026",
    badge: "Future Planning",
    icon: Sparkles,
    theme: {
      background: "linear-gradient(135deg, #283d3b 0%, #197278 100%)",
      badgeGradient: "linear-gradient(135deg, #ff9f1c 0%, #ffbf69 100%)",
      highlight: "#ffffff"
    }
  },
  NEW_YEAR_2026: {
    startDate: new Date("January 1, 2026 00:00:00").getTime(),
    endDate: new Date("January 7, 2026 23:59:59").getTime(),
    title: "Creative Goals Bundle",
    discount: "50%",
    code: "CREATE2026",
    badge: "New Year Creativity",
    icon: Star,
    theme: {
      background: "linear-gradient(135deg, #14213d 0%, #1b4965 100%)",
      badgeGradient: "linear-gradient(135deg, #fca311 0%, #ffb703 100%)",
      highlight: "#e9d8a6"
    }
  }
};


const RotatingSaleBanner = () => {
  const [currentPhase, setCurrentPhase] = useState(null);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const [copyStatus, setCopyStatus] = useState('Click to copy');
  const [showMessage, setShowMessage] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);


    // Add visibility on mount unless on pricing page
    useEffect(() => {
      if (!window.location.pathname.includes('/pricing')) {
        const timer = setTimeout(() => {
          setIsVisible(true);
        }, 100);
        return () => clearTimeout(timer);
      } else {
        setIsVisible(true); // Immediately show on pricing page without animation
        navigateToPricing();
      }
    }, []);

  useEffect(() => {
    const determinePhase = () => {
      const now = new Date().getTime();
      
      // First check if we're in an active sale period
      for (const [phase, details] of Object.entries(SALE_PHASES)) {
        if (now >= details.startDate && now <= details.endDate) {
          return { ...details, phase, upcoming: false };
        }
      }
      
      // If no active sale, find the next upcoming sale
      const upcomingPhases = Object.entries(SALE_PHASES)
        .filter(([_, details]) => details.startDate > now)
        .sort(([_, a], [__, b]) => a.startDate - b.startDate);
      
      if (upcomingPhases.length > 0) {
        const [phase, details] = upcomingPhases[0];
        return { 
          ...details, 
          phase,
          upcoming: true,
          badge: `Starting ${new Date(details.startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`,
        };
      }

      const firstPhase = Object.entries(SALE_PHASES)[0];
      return {
        ...firstPhase[1],
        phase: firstPhase[0],
        startDate: new Date(firstPhase[1].startDate).setFullYear(new Date().getFullYear() + 1),
        endDate: new Date(firstPhase[1].endDate).setFullYear(new Date().getFullYear() + 1),
        upcoming: true
      };
    };

    setCurrentPhase(determinePhase());
    const interval = setInterval(() => setCurrentPhase(determinePhase()), 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!currentPhase) return;

    const updateTimer = () => {
      const now = new Date().getTime();
      const targetDate = currentPhase.upcoming ? currentPhase.startDate : currentPhase.endDate;
      const distance = targetDate - now;

      setTimeLeft({
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000)
      });
    };

    updateTimer();
    const timer = setInterval(updateTimer, 1000);
    return () => clearInterval(timer);
  }, [currentPhase]);

  const copyCode = async () => {
    if (!currentPhase) return;
    try {
      await navigator.clipboard.writeText(currentPhase.code);
      setCopyStatus('Copied! 🎉');
      setShowMessage(true);
      setShowConfetti(true);
      
      setTimeout(() => {
        setShowMessage(false);
        setCopyStatus('Click to copy');
      }, 2000);
      
      // Wait for confetti animation to complete, then navigate
      setTimeout(() => {
        setShowConfetti(false);
        // Check if we're not already on the pricing page
        if (!window.location.pathname.includes('/pricing')) {
          window.open(`/pricing#billing-cycle?couponCode=${currentPhase.code}`, '_blank');
        } else {
          // If on pricing page, scroll to pricing section
          const pricingSection = document.getElementById('billing-cycle');
          if (pricingSection) {
            pricingSection.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 2000);
    } catch (err) {
      //console.error('Failed to copy:', err);
    }
  };

  const navigateToPricing = () => {
    if (!window.location.pathname.includes('/pricing')) {
      window.open(`/pricing#billing-cycle?couponCode=${currentPhase.code}`, '_blank');
    } else {
      // If on pricing page, scroll to pricing section
      const pricingSection = document.getElementById('current-plan-badge');
      if (pricingSection) {
        pricingSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  if (!currentPhase) return null;

  const Icon = currentPhase.icon;

  return (
    <>
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={500}
          gravity={0.3}
        />
      )}
      <div 
        id="banner" 
        className={isVisible ? 'visible' : ''}
        style={{
          background: currentPhase.theme.background
        }}
      >
        <div className="banner-content">
          {/* Left section */}
          <div className="left-content">
            <div 
              className="sale-badge"
              style={{
                background: currentPhase.theme.badgeGradient
              }}
            >
              <span className="sale-pulse"></span>
              <Icon className="badge-icon" size={16} />
              {currentPhase.badge}
            </div>
            <div className="timer">
              <div className="timer-container">
                <div className="time-unit">
                  <div className="time-value">{String(timeLeft.days).padStart(2, '0')}</div>
                  <div className="time-label">Days</div>
                </div>
                <span className="time-separator">:</span>
                <div className="time-unit">
                  <div className="time-value">{String(timeLeft.hours).padStart(2, '0')}</div>
                  <div className="time-label">Hours</div>
                </div>
                <span className="time-separator">:</span>
                <div className="time-unit">
                  <div className="time-value">{String(timeLeft.minutes).padStart(2, '0')}</div>
                  <div className="time-label">Mins</div>
                </div>
                <span className="time-separator">:</span>
                <div className="time-unit">
                  <div className="time-value">{String(timeLeft.seconds).padStart(2, '0')}</div>
                  <div className="time-label">Secs</div>
                </div>
              </div>
            </div>
          </div>

          {/* Center content */}
          <div className="center-content">
            <div className="message">
              <span className="sale-title">{currentPhase.title}</span>
              <div className="discount-text">
                Save up to <span className="highlight" style={{ color: currentPhase.theme.highlight }}>{currentPhase.discount} off</span>
              </div>
              <span 
                className="offer-details cursor-pointer hover:underline"
                onClick={navigateToPricing}
              >
                ACROSS ALL PLANS AND UPGRADES
              </span>
            </div>
          </div>

          {/* Right content */}
          <div className="right-content">
            <div className="coupon-container">
              <div className="coupon-code-container">
                <div 
                  className={`coupon-code ${showMessage && copyStatus === 'Copied! 🎉' ? 'copied' : ''}`}
                  onClick={copyCode}
                >
                  <span className="code-text">{currentPhase.code}</span>
                </div>
                <div className={`copy-message ${showMessage ? 'show-message' : ''}`}>
                  {copyStatus}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RotatingSaleBanner;