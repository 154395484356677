import React from 'react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCurrentUser } from '@aws-amplify/auth';
import NavBar from '../ui-components/LandingPage/Navbar';
import NewHero from '../ui-components/LandingPage/NewHero';
import VisualDemo from '../ui-components/LandingPage/VisualDemo';
import Footer from '../ui-components/LandingPage/Footer';
import WallOfLove from '../ui-components/LandingPage/WallOfLove';

export default function Landing() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check URL parameters for referral
    const checkReferral = () => {
      const urlParams = new URLSearchParams(location.search);
      const referralParam = urlParams.get('referral');
  
      if (referralParam) {
        // Always store just the latest referral as an object
        const referral = {
          referralCode: referralParam,
          timestamp: new Date().toISOString(),
          fullUrl: window.location.href,
          eventType: "signup"
        };
  
        // Store the latest referral object
        localStorage.setItem('pending_referral', JSON.stringify(referral));
      }
    };


    // Check if the user is already logged in
    const checkUserLoggedIn = async () => {
      try {
        const user = await getCurrentUser();
        if (user) {
          navigate('/app'); // Redirect to /app if the user is logged in
        }
      } catch (error) {
        //console.log('Not logged in');
      }
    };

    // Run both checks
    checkReferral();
    checkUserLoggedIn();
  }, [navigate, location]); // Add both navigate and location to the dependency array

  return (
    <div>
      <NavBar />
      <NewHero />
      <VisualDemo /> 
      <WallOfLove />
      <Footer />
    </div>
  );
}