import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from '@react-hook/window-size';
import NavBar from '../ui-components/LandingPage/Navbar';
import getUserData from '../userManagement/getUserData';
import Footer from '../ui-components/LandingPage/Footer';

const Congratulations = () => {
  const [width, height] = useWindowSize();
  const [user, setUser] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  
  const product = {
    name: "Pro Membership",
    image: "https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExcW0yZHl3ZjE5MWMzM2V3eTZ1ZHpiYjV0dnQycGwwenRkMWxnejg2aSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/HloNK1z39EkEQcreIo/giphy.webp"
  };

  useEffect(() => {
    const fetchUserAndTrackConversion = async () => {
      try {
        const userData = await getUserData();
        
        if (userData) {
          setUser(userData);
          
          // Track conversion immediately after getting user data
          if (userData.MembershipPlan !== "Freemium" && (!('conversionTracked' in userData) || userData.conversionTracked === false)) {
            try {
              const referralCode = userData.referralInfo.referralCode;

              if (referralCode) {
                const response = await fetch('https://gpzabe3zce.execute-api.us-east-1.amazonaws.com/dev/', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    referral: {
                      referralCode,
                      eventType: 'conversion',
                      userId: userData.userID 
                    }
                  })
                });

                if (response.ok) {
                  //console.log('Conversion tracked successfully');
                } else {
                  console.error('Failed to track conversion');
                }
              } else {
                //console.log('No referral code found');
              }
            } catch (error) {
              console.error('Error tracking conversion:', error);
            }
          } else {
            //console.log("conversion is already tracked");
          }
        } else {
          //console.log('No user data returned');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserAndTrackConversion();
  }, []); // Empty dependency array ensures this runs only once on mount


  const getFirstName = (fullName) => {
    if (!fullName) return '';
    return fullName.split(' ')[0];
  };

  const buttonStyle = {
    width: '200px',
    margin: 'auto',
    border: 'none',
    borderRadius: '24px',
    padding: '10px',
    fontWeight: '600',
    textDecoration: 'underline',
    backgroundColor: isHovered ? '#f5a4ab' : '#f0d1d3',
    cursor: 'pointer',
    transition: 'background-color 0.3s'
  };

  // Add a loading state check
  if (!user) {
    return (
      <>
        <NavBar />
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh' 
        }}>
          <p>Loading user information...</p>
        </div>
        <Footer />
      </>
    );
  }

  const greeting = user.Name ? `${getFirstName(user.Name)}, you are in 🎉` : "You are in 🎉";

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', paddingBottom: '50px', textAlign: 'center' }}>
        <Confetti width={width} height={height} numberOfPieces={450} recycle={false} />
        <NavBar />
        <h1 style={{ paddingTop: '50px', fontWeight: '900' }}>{greeting}</h1>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ width: '400px', fontSize: '18px', color: 'grey', margin: 'auto'}}>Thanks for unlocking more credits on Map This. Create amazing mind maps and keep on learning!</p>
          <img src={product.image} alt={product.name} style={{ maxWidth: '400px', margin: '20px auto', borderRadius:'16px'  }} />
          <button
            style={buttonStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => window.location.href = '/app'}
          >
            Go to my maps →
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Congratulations;