import React, { useState, useEffect, useRef } from 'react';
import {Amplify} from 'aws-amplify';
import config from '../amplifyconfiguration.json';
import '@aws-amplify/ui-react/styles.css';
import { checkForSummaryFile } from '../util/Retriever';
import '../App.css';
import {uploadData } from 'aws-amplify/storage';
import Mermaid from '../util/mermaid';
import 'font-awesome/css/font-awesome.min.css';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import IframeResizer from 'iframe-resizer-react'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { Authenticator } from '@aws-amplify/ui-react';
import getUserData from '../userManagement/getUserData';
import './pdftomindmap.css'
import { grid } from 'ldrs'
import resetUserUsage from '../userManagement/monthlyUsageRefresh';
import mermaid from 'mermaid';
import { downloadData } from '@aws-amplify/storage';
import SideNavBar from '../ui-components/SideNavBar'
import TileGallery from '../ui-components/TileGallery';
import UploadModal from '../ui-components/uploadmodal';
import useFileUploadText from '../util/FileUploadText';
import { retrieveProcessedFile } from '../util/RetrieverText';
import checkAndAddUserIdentity from '../util/sessionLogger';
import EditModal from '../ui-components/EditModal';
import 'aws-amplify/auth/enable-oauth-listener';
import TestimonialPopupModal from '../ui-components/UserTestimonial/testimonialPopup';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import FormatFileName from '../util/FormatFileName';
import useMindMapNodeInteractions from '../Hooks/useMindMapNodeInteractions'; // Adjust the path as necessary
import useFetchUserData from '../Hooks/useFetchUserData';
import SummaryEditor from '../ui-components/SummaryEditor';
import { saveAsPNG } from '../util/SaveFunctions/SaveAsPNG';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import { generateShareableLink } from '../util/shareMindMap';
import { fetchAuthSession } from '@aws-amplify/auth';
import HamsterLoader from '../ui-components/HamsterLoader';
import ShareLinkComponent from '../ui-components/ShareLinkComponent';
import IsSummaryLoading from '../util/IsSummaryLoading';
import MindmapRatings from '../util/MindmapRatings';
import ReMindMap from '../ui-components/ReMindMap';
import getMetaData from '../util/getMetaData';
import { sprig } from '@sprig-technologies/sprig-browser';
import MermaidGantt from '../util/mermaidGantt';
import { FaSpinner } from 'react-icons/fa';
import ReactFlowChart from '../ui-components/ReactFlow/ReactFlowComponents/ReactFlow';
import { ReactFlowProvider } from 'reactflow';
import ChatPanel from '../ui-components/ChatPanel';
import FlowchartEditModal from '../ui-components/FlowchartEditModal';
import MermaidControlsHeader from '../ui-components/MermaidControlsHeader';
import { handleStreamingResponse } from './streamingHandler';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import { cacheService } from '../ui-components/cacheService';

grid.register();


export const Sprig = sprig.configure({
environmentId: 'eEI-K58BDxxN',
})



Amplify.configure(config);
library.add(fas);
library.add(far);
resetUserUsage();


function PDFToMindMap() {

  const defaultMindmap = `mindmap
  root((MapThis))
      PDF_Input[PDF Input]
          Upload_PDF[Upload PDF]
          Parse_PDF[Parse PDF]
              Extract_Text[Extract Text]
      Mindmap_Generation[Mindmap Generation]
          Analyze_Data[Analyze Data]
          Identify_Key_Points[Identify Key Points]
              Create_Structure[Create Mindmap Structure]
      Output_Display[Output Display]
          View_Options[View Options]
              Zoom_Pan[Zoom & Pan]
              Export[Export Options]
`;

  const [mindMapCode, setMindMapCode] = useState(defaultMindmap);
  useEffect(() => {
  }, [mindMapCode]);




  const [isLoading, setIsLoading] = useState(false);

  const [isMindMapReady, setIsMindMapReady] = useState(false);

  const [showFileUpload, setShowFileUpload] = useState(false);
  
  const [fileKey, setFileKey] = useState("");

  const [isFormLoading, setFormIsLoading] = useState(false);

  const [feedback, setFeedback] = useState({ type: '', message: '' });

  const [creditsNeeded, setCreditsNeeded] = useState(0);

  const [zoomLevel, setZoomLevel] = useState(4.5);

  const [showTileGallery, setShowTileGallery] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [textInput, setTextInput] = useState('');

  const [showUploadModalButton, setShowUploadModalButton] = useState(false);

  const [newMindmapButtonClicked, setNewMindmapButtonClicked] = useState(false);

  const [uploadedFileName, setUploadedFileName] = useState('');

  const [mapUpdateTrigger, setMapUpdateTrigger] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);

  const [feedbackGiven, setFeedbackGiven] = useState(false);

  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const [showTestimonialWidget, setShowTestimonialWidget] = useState(false);

  const [currentSummary, setCurrentSummary] = useState('');

  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);

  const [mindMapView , setMindMapView] = useState(false);

  const feedbackFormRef = useRef(null);

  const nodeSummaries = []; // Array to hold { nodeText, summary } objects

  const [showMindMapScreen, setShowMindMapScreen] = useState(false);

  const [isPricingPopupOpen, setPricingPopupOpen] = useState(false);

  const [showConfetti, setShowConfetti] = useState(false);

  const [shareableUrl, setShareableUrl] = useState('');

  const [showSharePopup, setShowSharePopup] = useState(false); 

  const [isShareLoading, setIsShareLoading] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState('');

  const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);

  const [activateNodeClicks, setActivateNodeClicks] = useState(true);

  const [identityId, setIdentityId] = useState('');

  const [userId, setuserId] = useState('');

  const [summaryGenerated, setSummaryGenerated] = useState(true);

  const dropdownRef = useRef(null);

  const [rating, setRating] = useState(null);

  const [isUserOnboarded, setIsUserOnboarded] = useState(true);

  const [metadata, setMetadata] = useState({});

  const [redo, setRedo] = useState("false");

  const [redoLoading, setRedoLoading] = useState();

  const [userEmail, setUserEmail] = useState('');

  const [showErrorScreen, setShowErrorScreen] = useState(false);

  const [exportDropdown, setExportDropdown] = useState(false);
  const exportRef = useRef(null);

  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [prevIsSummaryLoading, setPrevIsSummaryLoading] = useState(false);

  const [isMindMap, setIsMindMap] = useState(false);
  const [isGanttChart, setIsGanttChart] = useState(false);
  const [isFlowChart, setIsFlowChart] = useState(false);
  const [isReactFlow, setIsReactFlow] = useState(false); 
  const [showMindmapHeader, setShowMindmapHeader] = useState(true);
  const [isChatPanelOpen, setIsChatPanelOpen] = useState(true);
  const [addLeftPadding, setAddLeftPadding] = useState(true);
  const [cacheReset, setCacheReset] = useState(false);
  
  useEffect(() => {
  
    // Reset all diagram type states
    setIsMindMap(false);
    setIsGanttChart(false);
    setIsFlowChart(false);
  
    // Extract the first word from mindMapCode
    const firstWord = mindMapCode.trim().split(/\s+/)[0]; // Split by spaces and get the first word
  
    // Manually detect the type based on the first word
    //console.log(firstWord);
    if (firstWord.toLowerCase() === 'flowchart' || firstWord.toLowerCase() === 'graph') {
      setIsFlowChart(true);
    } else if (firstWord.toLowerCase() === 'gantt') {
      setIsGanttChart(true);
    } else if (firstWord.toLowerCase() === 'mindmap') {
      setIsMindMap(true);
    } else if (firstWord.startsWith('{')) {
      setIsReactFlow(true);
      setAddLeftPadding(false);
    } 
  
  }, [mindMapCode]); // This effect runs whenever `mindMapCode` changes
  



  const toggleDropdown = () => {
    setExportDropdown(prev => !prev);
  };


  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserData();
      setIsUserOnboarded(data.isUserOnboarded);
      setUserEmail(data.Email);
      if(data.isUserOnboarded === false) {
        navigate('/onboarding');
      }
    };
    fetchData();
  }, []); 

  const handleExportClickOutside = (event) => {
    if (exportRef.current && !exportRef.current.contains(event.target)) {
      setExportDropdown(false);
    }
  };

  const handleExportEscape = (event) => {
    if (event.key === 'Escape') {
      setExportDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleExportClickOutside);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleExportClickOutside);
      document.removeEventListener('keydown', handleExportEscape);
    };
  }, []);
  

  useEffect(() => {
    const currentSession = async () => {
      try {
        const data = await fetchAuthSession();
        setIdentityId(data.identityId);
        setuserId(data.userSub);
      } catch (err) {
        //console.log(err);
      }
    };
    currentSession();
  }, []);


  const toggleLanguageDropdown = () => setLanguageDropdownVisible(prevState => !prevState);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setLanguageDropdownVisible(false);
    }
  };

  const handleEscape = (event) => {
    if (event.key === 'Escape') {
      setLanguageDropdownVisible(false);
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  const languages = [
    { code: 'af', name: 'Afrikaans' },
    { code: 'sq', name: 'Albanian' },
    { code: 'am', name: 'Amharic' },
    { code: 'ar', name: 'Arabic' },
    { code: 'hy', name: 'Armenian' },
    { code: 'az', name: 'Azerbaijani' },
    { code: 'bn', name: 'Bengali' },
    { code: 'bs', name: 'Bosnian' },
    { code: 'bg', name: 'Bulgarian' },
    { code: 'ca', name: 'Catalan' },
    { code: 'zh', name: 'Chinese (Simplified)' },
    { code: 'zh-TW', name: 'Chinese (Traditional)' },
    { code: 'hr', name: 'Croatian' },
    { code: 'cs', name: 'Czech' },
    { code: 'da', name: 'Danish' },
    { code: 'fa-AF', name: 'Dari' },
    { code: 'nl', name: 'Dutch' },
    { code:'en', name: 'English' },
    { code:'en-UK', name: 'English (UK)'},
    { code: 'et', name: 'Estonian' },
    { code: 'fa', name: 'Farsi (Persian)' },
    { code: 'tl', name: 'Filipino, Tagalog' },
    { code: 'fi', name: 'Finnish' },
    { code: 'fr', name: 'French' },
    { code: 'fr-CA', name: 'French (Canada)' },
    { code: 'ka', name: 'Georgian' },
    { code: 'de', name: 'German' },
    { code: 'el', name: 'Greek' },
    { code: 'gu', name: 'Gujarati' },
    { code: 'ht', name: 'Haitian Creole' },
    { code: 'ha', name: 'Hausa' },
    { code: 'he', name: 'Hebrew' },
    { code: 'hi', name: 'Hindi' },
    { code: 'hu', name: 'Hungarian' },
    { code: 'is', name: 'Icelandic' },
    { code: 'id', name: 'Indonesian' },
    { code: 'ga', name: 'Irish' },
    { code: 'it', name: 'Italian' },
    { code: 'ja', name: 'Japanese' },
    { code: 'kn', name: 'Kannada' },
    { code: 'kk', name: 'Kazakh' },
    { code: 'ko', name: 'Korean' },
    { code: 'lv', name: 'Latvian' },
    { code: 'lt', name: 'Lithuanian' },
    { code: 'mk', name: 'Macedonian' },
    { code: 'ms', name: 'Malay' },
    { code: 'ml', name: 'Malayalam' },
    { code: 'mt', name: 'Maltese' },
    { code: 'mr', name: 'Marathi' },
    { code: 'mn', name: 'Mongolian' },
    { code: 'no', name: 'Norwegian (Bokmål)' },
    { code: 'ps', name: 'Pashto' },
    { code: 'pl', name: 'Polish' },
    { code: 'pt', name: 'Portuguese (Brazil)' },
    { code: 'pt-PT', name: 'Portuguese (Portugal)' },
    { code: 'pa', name: 'Punjabi' },
    { code: 'ro', name: 'Romanian' },
    { code: 'ru', name: 'Russian' },
    { code: 'sr', name: 'Serbian' },
    { code: 'si', name: 'Sinhala' },
    { code: 'sk', name: 'Slovak' },
    { code: 'sl', name: 'Slovenian' },
    { code: 'so', name: 'Somali' },
    { code: 'es', name: 'Spanish' },
    { code: 'es-MX', name: 'Spanish (Mexico)' },
    { code: 'sw', name: 'Swahili' },
    { code: 'sv', name: 'Swedish' },
    { code: 'ta', name: 'Tamil' },
    { code: 'te', name: 'Telugu' },
    { code: 'th', name: 'Thai' },
    { code: 'tr', name: 'Turkish' },
    { code: 'uk', name: 'Ukrainian' },
    { code: 'ur', name: 'Urdu' },
    { code: 'uz', name: 'Uzbek' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'cy', name: 'Welsh' },
  ];

  const {
    userCurrentUsage,
    userMembershipPlan,
    userMonthlyLimit,
    userID,
    reviewExists,
    userExceededLimit,
    dataForNav,
    isFirstMindMap,
  } = useFetchUserData();

  useEffect(() => {
    const configureAWS = async () => {
      try {
        const session = await fetchAuthSession();
        setIdentityId(session.identityId); // Assuming session object directly contains identityId
      } catch (error) {
        //console.error("Error fetching auth session:", error);
      }
    };
  
    configureAWS();
  }, []);

  useEffect(() => {
    if (isFirstMindMap) {
      setShowConfetti(true);
      const timer = setTimeout(() => {
        setShowConfetti(false); // Hide confetti after timeout
      }, 5000); // Adjust timeout as needed
      return () => clearTimeout(timer);
    }
  }, [isMindMapReady]);

  useEffect(() => {
    if (isFirstMindMap) {
      setIsModalOpen(true);
    }
  }, [isFirstMindMap]);

  const [myStyle, setMyStyle] = useState({});
  const handleGenerateLink = async (event) => {
    setIsShareLoading(true);
    const { clientX, clientY } = event;
    const url = await generateShareableLink(identityId, fileKey);
    setShareableUrl(url);
    setIsShareLoading(false);
    setShowSharePopup(true);
    setMyStyle({
      position: 'absolute',
      left: `${clientX - 220}px`,
      top: `${clientY + 40}px`,
    });
  };

  const handleClose = () => {
    setShowSharePopup(false);
};

      // New function to toggle the pricing popup
      const togglePricingPopup = () => {
        setShowFileUpload(false);
        setPricingPopupOpen(!isPricingPopupOpen);
      };
  


  const {closeAllNodes, activeSummary, setActiveSummary } = useMindMapNodeInteractions(true, mindMapCode);

  const { uploadTextAsFile, uploadAIMagicTextAsFile, uploadProgress } = useFileUploadText({userID});


  const handleUpdateSummary = async (nodeText, newSummary) => {

    // Normalize newSummary by removing new lines
    const sanitizedNewSummary = newSummary.replace(/\n+/g, ' ').trim();

    // Normalize whitespace and escape special characters for regex in nodeText
    const nodeTextEscaped = nodeText.replace(/\s+/g, ' ').trim();
    const regexPattern = new RegExp(`["'\`\\[\\{]?${nodeTextEscaped.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}["'\`\\]\\}]?`, 'i');

    const lines = mindMapCode.split('\n');
    let updatedLines = [];
    let foundNodeIndex = -1;

    // First, identify the line index where the node text is found
    lines.forEach((line, index) => {
        if (regexPattern.test(line.trim())) {
            foundNodeIndex = index; // Capture the index of the node line
        }
    });

    // If a matching node is found, proceed to replace its preceding summary
    if (foundNodeIndex !== -1) {
      let summaryAdded = false;
      lines.forEach((line, index) => {
        if (index === foundNodeIndex - 1 && line.trim().startsWith('%%')) {
          // This is the summary line directly above the found node, replace it
          updatedLines.push(`%% ${sanitizedNewSummary}`);
          summaryAdded = true;
        } else {
          // This line is not the one being replaced, add it as is
          updatedLines.push(line);
        }
      });

      if (!summaryAdded) {
        // Add the new summary line above the found node
        updatedLines.splice(foundNodeIndex, 0, `%% ${sanitizedNewSummary}`);
      }

      const updatedMindMapCode = updatedLines.join('\n');

      // Assuming you have a setState method for mindMapCode
      setMindMapCode(updatedMindMapCode);

      // Upload the updated code
      try {
        const file = new File([updatedMindMapCode], fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt'), { type: 'text/plain' });
        await uploadData({
          key: fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt'),
          data: file,
          options: { accessLevel: 'private' }
        });


      } catch (error) {
        //console.error("Error updating and uploading mind map:", error);
      }
    } else {

    }
  };

  

const handleCloseSummary = () => {
  setIsSummaryModalOpen(false); // This effectively closes the SummaryEditor
};

  
useEffect(() => {
  // Show the modal if there is an active summary, otherwise hide it.
  setIsSummaryModalOpen(!!activeSummary);
}, [activeSummary]);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (feedbackFormRef.current && !feedbackFormRef.current.contains(event.target)) {
        setFeedbackOpen(false);
      }
    };
  
    if (feedbackOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [feedbackOpen]); // Add feedbackOpen as a dependency
  

  const openEditModal = () => setShowEditModal(true);
  const closeEditModal = () => {
    setShowEditModal(false);
    setIsMindMapReady(true);
  }

  const handleFeedbackStatus = (status) => {
    setFeedbackGiven(status);
  };


  useEffect(() => {
    fetchMindMapData();
  }, [mapUpdateTrigger]);

  // Hypothetical fetch function
  const fetchMindMapData = async () => {
    //console.log(fileKey);
    const fileKeyFetch = fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt');
    //console.log(fileKeyFetch);
    try{
        const downloadResult = await downloadData({
          key: `${fileKeyFetch}`,
          options: { accessLevel: 'private' }
      }).result;
      const textContent = await downloadResult.body.text();
      //console.log("this is as soon as data is downloaded for edit modal: "+ textContent);
      setMindMapCode(textContent);
    }
    catch (error) {
      //console.error('Error loading file content:', error);
    }
  };



  const onMindMapSaved = () => {
    setMapUpdateTrigger(current => !current); // Toggle to trigger useEffect
  };


  useEffect(() => {
    checkAndAddUserIdentity();
    
  }, []);



  const openModal = () => {
    setIsModalOpen(true);
    setNewMindmapButtonClicked(true);// Set button to clicked state for visual feedback
  };

  // Function to close the modal
  const closeModal = () => {
    setShowTileGallery(true);
    setIsModalOpen(false);
    setNewMindmapButtonClicked(false);
  };

  const handleWheel = (e) => {
    if (showEditModal) {
      // If the edit modal is open, do not proceed with zooming.
      e.preventDefault();
      return;
    }


    e.preventDefault();
    const zoomFactor = 0.1; // Adjust this value as needed
    if (e.deltaY < 0) {
      setZoomLevel(zoomLevel => Math.min(zoomLevel + zoomFactor, 4.5)); // Max zoom level 3x
    } else {
      setZoomLevel(zoomLevel => Math.max(zoomLevel - zoomFactor, 0.5)); // Min zoom level 0.5x
    }
  };

/*  const handleTextUpload = (text, creditsNeeded, mapType) => {
    setIsMindMapReady(false);
    setIsLoading(true); 
    uploadTextAsFile(
        text,
        creditsNeeded,
        mapType,
        async (fileKey) => {
            setFileKey(fileKey);
            const formattedFileName = FormatFileName(fileKey); // Format the file name
            setUploadedFileName(formattedFileName);
            setShowErrorScreen(false);
            setIsModalOpen(false);
            setShowTileGallery(false);
            setIsLoading(true);
            retrieveProcessedFile(
                fileKey,
                async (textContent) => {
                    setMindMapCode(textContent); // Update mind map code
                    setIsMindMapReady(true);
                    setShowUploadModalButton(true); 
                    setIsLoading(false); // Hide loader
                    //console.log("updating user usage");
                    //await updateUserUsage(creditsNeeded);
                    //console.log("it is done!!!");
                    const updatedUserData = await getUserData();
                    if(updatedUserData) {
                      setUserDataUpdateTrigger(updatedUserData.userUsage.CurrentMonthUsage);
                    }
                },
                (error) => {
                    //console.error('Error retrieving processed file:', error);
                    setIsLoading(false); // Hide loader
                }
            );
        },
        (error) => {
            //console.error('Error uploading text:', error);
            setIsLoading(false); // Hide loader
        }
    );
  };
  */

  const handleTextUpload = (text, creditsNeeded, mapType) => {
    setIsMindMapReady(false);
    setIsLoading(true);
    
    let toastId;
    uploadTextAsFile(
      text,
      creditsNeeded,
      mapType,
      async (fileKey) => {
        setFileKey(fileKey);
        const formattedFileName = FormatFileName(fileKey);
        setUploadedFileName(formattedFileName);
        setShowErrorScreen(false);
        setIsModalOpen(false);
        setShowTileGallery(false);
        
        let receivedFirstChunk = false;
        let chunks = [];
        
        // Create a single toast instance
        toastId = toast.loading('Processing your file...', {
          position: 'bottom-right',
        });
        
        // Get the complete path for the file
        const completePath = `private/${identityId}/${fileKey}`;
        
        handleStreamingResponse(
          completePath,
          (chunk) => {
            if (!receivedFirstChunk) {
              setIsLoading(false);
              receivedFirstChunk = true;
              setIsMindMapReady(true);
              setShowUploadModalButton(true);
            }
  
            // Update the toast dynamically with progress information
            toast.loading('Generating mindmap...', {
              id: toastId,
            });
        
            // Keep track of all chunks with their original whitespace
            if (chunk.trim()) {
              chunks.push(chunk.includes('\n') ? chunk : chunk + '\n');
              
              // Combine all chunks, preserving original whitespace
              let mindmapContent;
              if (chunks[0].trim() === 'mindmap') {
                // Handle the first 'mindmap' chunk
                mindmapContent = 'mindmap\n';
                // Combine the rest of the chunks
                mindmapContent += chunks.slice(1).join('');
              } else {
                mindmapContent = chunks.join('');
              }
      
              // Update mindmap code with preserved formatting
              setMindMapCode(mindmapContent);
            }
          },
          async (error) => {
            setShowErrorScreen(true);
            setIsLoading(false);
            setIsMindMapReady(false);
            toast.error('Failed to generate mindmap. Please try again!', {
              id: toastId,
            });
          },
          () => {
            // Success handling
            toast.success('Mindmap generated successfully', {
              id: toastId,
            });
          }
        );
        
        // Update user data after successful processing
        try {
          const updatedUserData = await getUserData();
          if (updatedUserData) {
            setUserDataUpdateTrigger(updatedUserData.userUsage.CurrentMonthUsage);
          }
        } catch (error) {
          // Handle error updating user data
        }
      },
      (error) => {
        setIsLoading(false);
        setShowErrorScreen(true);
        toast.update(toastId, {
          render: 'Failed to upload file. Please try again.',
          type: 'error',
          isLoading: false,
          autoClose: 4000,
        });
      }
    );
  };

  /*
  const handleAIMagicUpload = (aiText, creditsNeeded, mapType) => {
    setIsMindMapReady(false);
    setIsLoading(true); 
    uploadAIMagicTextAsFile(
      aiText,
      creditsNeeded,
      mapType,
      async (fileKey) => {
            setFileKey(fileKey);
            const formattedFileName = FormatFileName(fileKey); // Format the file name
            setUploadedFileName(formattedFileName);
            setShowErrorScreen(false);
            setIsModalOpen(false);
            setShowTileGallery(false);
            setIsLoading(true);
            retrieveProcessedFile(
                fileKey,
                async (textContent) => {
                    
                    setMindMapCode(textContent); // Update mind map code
                    setIsMindMapReady(true);
                    setShowUploadModalButton(true); // Indicate that the mind map is ready
                    setIsLoading(false);
                    const updatedUserData = await getUserData();
                    if(updatedUserData) {
                      setUserDataUpdateTrigger(updatedUserData.userUsage.CurrentMonthUsage);
                    } // Hide loader 
                },
                (error) => {
                  //console.error('Error uploading AI Magic text:', error);
                  setIsLoading(false);  
                }
            );
        },
        (error) => {
            //console.error('Error uploading text:', error);
            setIsLoading(false); // Hide loader
        }
    );
  };
*/

const handleAIMagicUpload = (aiText, creditsNeeded, mapType) => {
  setIsMindMapReady(false);
  setIsLoading(true);
  
  let toastId;
  uploadAIMagicTextAsFile(
    aiText,
    creditsNeeded,
    mapType,
    async (fileKey) => {
      setFileKey(fileKey);
      const formattedFileName = FormatFileName(fileKey);
      setUploadedFileName(formattedFileName);
      setShowErrorScreen(false);
      setIsModalOpen(false);
      setShowTileGallery(false);

      let receivedFirstChunk = false;
      let chunks = [];

              // Create a single toast instance
           toastId = toast.loading('Processing your file...', {
                position: 'bottom-right',
              });


      const completePath = `private/${identityId}/${fileKey}`;

      handleStreamingResponse(
        completePath,
        (chunk) => {
          if (!receivedFirstChunk) {
            setIsLoading(false);
            receivedFirstChunk = true;
            setIsMindMapReady(true);
            setShowUploadModalButton(true);
          }

          // Update the toast dynamically with progress information
          toast.loading('Generating mindmap...', {
            id: toastId,
          });

          if (chunk.trim()) {
            chunks.push(chunk.includes('\n') ? chunk : chunk + '\n');

            let mindmapContent;
            if (chunks[0].trim() === 'mindmap') {
              mindmapContent = 'mindmap\n';
              mindmapContent += chunks.slice(1).join('');
            } else {
              mindmapContent = chunks.join('');
            }

            setMindMapCode(mindmapContent);
          }
        },
        async (error) => {
          // Error handling
          setShowErrorScreen(true);
          setIsLoading(false);
          setIsMindMapReady(false);
          toast.error('Failed to generate mindmap. Please tty again!', {
            id: toastId,
          });
        },
        () => {
          // Success handling
          toast.success('Mindmap generated successfully', {
            id: toastId,
          });
        }
      );

      // Update user data after successful processing
      try {
        const updatedUserData = await getUserData();
        if (updatedUserData) {
          setUserDataUpdateTrigger(updatedUserData.userUsage.CurrentMonthUsage);
        }
      } catch (error) {
        // Handle error updating user data
      }
    },
    (error) => {
      // Error handling for uploading text
      setIsLoading(false);
      setShowErrorScreen(true);
      toast.update(toastId, {
        render: 'Failed to upload file. Please try again.',
        type: 'error',
        isLoading: false,
        autoClose: 4000,
      });
    }
  );
};


  const navigate = useNavigate();
  const handleMyMapsClick = () => {
    setIsMindMapReady(false); 
    setMindMapView(false);
    setShowTileGallery(true); // Show TileGallery
    navigate('/');
  };

  const handleMindMapUpdate = async (fileName) => {
    setFileKey(fileName);
    setIsLoading(true);
    setMindMapView(true);
    const formattedFileName = FormatFileName(fileName); // Format the file name
    setUploadedFileName(formattedFileName);
    let summaryFileName;
    if (fileName.endsWith('.pdf')) {
        summaryFileName = `${fileName.replace('.pdf', '')}_summary.txt`;
    } else if (fileName.endsWith('ai_magic.txt') || fileName.endsWith('text_input.txt')) {
        summaryFileName = `${fileName.replace('.txt', '')}_summary.txt`;
    }

    if (summaryFileName) {
        try {
            const downloadResult = await downloadData({
                key: summaryFileName,
                options: {
                    accessLevel: 'private'
                }
            }).result;
            let textContent = await downloadResult.body.text();
            //console.log("Before cleaning: ", textContent);
            textContent = textContent.replace(/```/g, "").replace(/mermaid/g, "").trim();
            setMindMapCode(textContent);
            setIsMindMapReady(true);
            setShowUploadModalButton(true);
            setZoomLevel(3);
            setShowFileUpload(false);
        } catch (error) {
            //console.error('Error fetching summary file:', error);
        } finally {
            setIsLoading(false);
        }
    } else {
        //console.error('Unsupported file type');
        setIsLoading(false);
    }
  };
  
  const handleCreditsNeededChange = (newCreditsNeeded) => {
      setCreditsNeeded(newCreditsNeeded);
  };

  const [userDataUpdateTrigger, setUserDataUpdateTrigger] = useState(0);

  const [errorMessage, setErrorMessage] = useState('');

  

  const handleToggleUpload = () => {
    setShowFileUpload(!showFileUpload);
  };

  const handleRetry = () => {
    setIsModalOpen(true); 
    setShowErrorScreen(false);
    setShowTileGallery(false);// Format the file name
    setShowFileUpload(false); // Hide the file upload component when a file is uploaded
  };
  

 // Function to be called when loading state transitions from true to false
 const onLoadingComplete = () => {
  // Fetch mind map data and reinitialize interactions
  fetchMindMapData();
  
  // Add a delay before calling closeAllNodes
  setTimeout(() => {
    closeAllNodes();
  }, 500); // Delay in milliseconds (e.g., 500ms)
};

useEffect(() => {
  const pollIsSummaryLoading = async () => {
    const loadingStatus = await IsSummaryLoading(fileKey, identityId);
    setIsSummaryLoading(loadingStatus);
  };

  pollIsSummaryLoading(); // Call it once to set initial state
  const intervalId = setInterval(pollIsSummaryLoading, 5000); // Poll every 5 seconds

  return () => clearInterval(intervalId); // Clean up the interval on component unmount
}, [fileKey, identityId]);

// Detect the transition from true to false
useEffect(() => {
  if (!prevIsSummaryLoading && !isSummaryLoading) {
    onLoadingComplete();
  }
  setPrevIsSummaryLoading(isSummaryLoading);
}, [isSummaryLoading, prevIsSummaryLoading]);
  

  /*function handleFileUploadSuccess(fileName) {
    
    closeModal();
    setShowTileGallery(false);
    setIsLoading(true);
    setFileKey(fileName);
    const formattedFileName = FormatFileName(fileName); // Format the file name
    setUploadedFileName(formattedFileName);
    setShowFileUpload(false); // Hide the file upload component when a file is uploaded
    

    checkForSummaryFile(fileName,
        async (content) => {
            try {
              const testMindMapCodeWithSyntaxError = `
              mindmappp
              root((mindmap))
                  Origins
                      Long history
                      ::icon(fa fa-book)
                      Popularisation
                          British popular psychology author Tony Buzan
                  Research
                      On effectiveness<br/>and features
                      On Automatic creation
                          Uses
                              Creative techniques
                              Strategic planning
                              Argument mapping
                  Tools
                      Pen and paper
                      Mermaid
              `;
                // Use mermaid API to parse the content
                let isValid = false;
                {
                      try {
                        // Try to validate using Mermaid
                        await mermaid.mermaidAPI.parse(content);
                        isValid = true;  // Valid Mermaid syntax
                    } catch (mermaidError) {
                        try {
                            // If Mermaid parsing fails, try to parse as JSON
                            const jsonObject = JSON.parse(content);
                            isValid = true;  // Valid JSON
                        } catch (jsonError) {
                            // Both Mermaid and JSON parsing failed
                            console.error('Invalid content: Neither valid Mermaid nor valid JSON');
                        }
                    }
                }
                if (isValid) {
                    // If content is valid, update usage and set the mind map code
                    setMindMapCode(content);
                    const updatedUserData = await getUserData();
                    if(updatedUserData) {
                      setUserDataUpdateTrigger(updatedUserData.userUsage.CurrentMonthUsage);
                    }
                    closeModal();
                    setIsMindMapReady(true);
                    setShowUploadModalButton(true);
                    setIsLoading(false);
                } else {
                    // Handle invalid content without updating usage
                    //console.error('Invalid Mermaid content');
                    setShowErrorScreen(true);
                    setIsLoading(false);
                    setIsMindMapReady(false);
                    setShowFileUpload(false); // Hide file upload to show error screen                    
                }
            } catch (error) {
              setShowErrorScreen(true);
              setIsLoading(false);
              setIsMindMapReady(false);
              setShowFileUpload(false); // Hide file upload to show error screen              
            }
            setIsLoading(false);
        },
        (error) => {
            // Handle error from checkForSummaryFile
            //console.error('Error fetching summary:', error);
            setShowErrorScreen(true);
            setIsLoading(false);
            setIsMindMapReady(false);
            setShowFileUpload(false); // Hide file upload to show error screen
        } 
    );
  };*/

  const handleFileUploadSuccess = (fileName) => {
    closeModal();
    setShowTileGallery(false);
    setIsLoading(true);
    setFileKey(fileName);
    const formattedFileName = FormatFileName(fileName);
    setUploadedFileName(formattedFileName);
    setShowFileUpload(false);
    const completePath = `private/${identityId}/${fileName}`;
  
    let receivedFirstChunk = false;
    let chunks = [];
  
    // Show initial loading toast
    const loadingToastId = toast.loading('Processing your file...', {
      position: 'bottom-right',
    });
  
    // Add 1 second delay before starting the streaming response
    setTimeout(() => {
      handleStreamingResponse(
        completePath,
        (chunk) => {
          if (!receivedFirstChunk) {
            setIsLoading(false);
            receivedFirstChunk = true;
            setIsMindMapReady(true);
            setShowUploadModalButton(true);
  
            // Update loading toast to show progress
            toast.loading('Generating mindmap...', {
              id: loadingToastId,
            });
          }
  
          // Keep track of all chunks with their original whitespace
          if (chunk.trim()) {
            chunks.push(chunk.includes('\n') ? chunk : chunk + '\n');
  
            // Combine all chunks, preserving original whitespace
            let mindmapContent;
            if (chunks[0].trim() === 'mindmap') {
              mindmapContent = 'mindmap\n';
              mindmapContent += chunks.slice(1).join('');
            } else {
              mindmapContent = chunks.join('');
            }
  
            setMindMapCode(mindmapContent);
          }
        },
        (error) => {
          // Dismiss loading toast and show error
          toast.dismiss(loadingToastId);
          toast.error('Failed to process file. Please try again.', {
            position: 'bottom-right',
            duration: 4000,
          });
  
          setShowErrorScreen(true);
          setIsLoading(false);
          setIsMindMapReady(false);
          setShowFileUpload(false);
        },
        () => {
          // Dismiss loading toast and show success message
          toast.dismiss(loadingToastId);
          toast.success('Mindmap generated successfully!', {
            position: 'bottom-right',
            duration: 4000,
          });
        }
      );
    }, 1000);
  };
  


  const handleSuccessfulEdit = async () => {
    
    try {
      setIsLoading(true);
      setShowMindmapHeader(false);
      setCacheReset(true);
      cacheService.clearCache();
  
      // Transform file key for summary
      const fileKeyFetch = fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt');
  
      // Download and extract text - note the .result directly after downloadData
      const downloadResult = await downloadData({
        key: fileKeyFetch,
        options: { accessLevel: 'private' }
      }).result;
  
      // Extract text from the response body
      const textContent = await downloadResult.body.text();
      
      if (!textContent) {
        throw new Error('Retrieved text content is empty');
      }
  
      setMindMapCode(textContent);
      return textContent;
  
    } catch (error) {
      console.error('Error in handleSuccessfulEdit:', error);
      throw error;
      
    } finally {
      setIsLoading(false);
      setShowMindmapHeader(true);
      setCacheReset(false);
    }
  };

  
  const translateMindMap = async () => {
    setIsLoading(true);
    setIsMindMapReady(false);
    setShowTileGallery(false);

    if (!selectedLanguage || !fileKey) {
      return;
    }

    // Adjusting for the expected file key format
    let newFileKey = fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt');
    let summaryFileKey = `private/${identityId}/${newFileKey}`;

  
    try {
      const response = await fetch('https://wox1fv3q4j.execute-api.us-east-1.amazonaws.com/dev', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // Correcting the property names as per the API structure
        body: JSON.stringify({ file_key: summaryFileKey, target_language: selectedLanguage }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to translate mind map');
      }
  
      const data = await response.json();
      setIsLoading(false);
      setIsMindMapReady(true);
      onMindMapSaved();
      // Optionally, trigger any UI update or notification here
    } catch (error) {
      //console.error('Error translating mind map:', error);
      // Consider providing user feedback on the UI
    }
  };


const isPaidUser = userMembershipPlan === 'Paid' || userMembershipPlan === 'Pro Plan' || userMembershipPlan === 'Starter Plan' ||userMembershipPlan === 'Annual Pro Plan' ;
const isFreeOrPayPerUse = userMembershipPlan === 'Freemium' || userMembershipPlan === 'Pay Per Use';
const handleReMindMapClick = () => {
  setIsLoading(true);
  setIsMindMapReady(false);
  setShowTileGallery(false);
  setMindMapCode('');
};


  useEffect(() => {
      // Construct the file path
      const filePath = `private/${identityId}/${fileKey}`;

      // Fetch metadata when the component mounts
      getMetaData(filePath)
          .then(data => {
              if (data) {
                  const parsedBody = JSON.parse(data.body);
                  setMetadata(parsedBody.metadata);
                  setRedo(parsedBody.metadata.redo);
                  setRedoLoading(parsedBody.metadata.redoloading);
                  if(parsedBody.metadata.redoloading === "true") {
                      setMindMapCode('');
                      setIsLoading(true);
                      setIsMindMapReady(false);
                      setShowTileGallery(false);
                  }
              } else {
                  
              }
          })
          .catch(error => {
              
          });

      // This empty dependency array ensures the effect runs only once after the component mounts
  }, [identityId, fileKey]);


  function isPDF(fileKey) {
    if (typeof fileKey !== 'string') {
      //console.error('Invalid input: fileKey must be a string.');
      return false;
    }
  
    // Check if the fileKey ends with '.pdf'
    return fileKey.toLowerCase().endsWith('.pdf');
  }

  const ispdf = isPDF(fileKey);

  Sprig.setAttributes({
    membershipPlan: userMembershipPlan
 });

 Sprig.setEmail(userEmail);

 const handleUpgradeNavigation = (event, pricingState = 'monthly') => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }
  window.open(`/pricing?state=${pricingState}`, '_blank');
}




return (
  <Authenticator>
    {({ signOut, user }) => (
      <div className="page-layout">
        {!isMindMapReady && (
          <SideNavBar 
            onMyMapsClick={handleMyMapsClick} 
            triggerUpdate={userDataUpdateTrigger}
            isFirstMindMap={isFirstMindMap}
          /> 
        )}

        <div className="relative flex w-full">


          {/* Main Content */}
          <div className={`main-content-wrapper transition-all duration-300
            ${isMindMapReady && isChatPanelOpen ? 'ml-96' : 'ml-0'}
            ${isChatPanelOpen && !showTileGallery ? 'with-chat-panel' : ''}`}
          >

            {showTestimonialWidget && (
              <div 
                style={{ position: 'absolute', top: '0%', left: '0%', width: '100%', height: '100%', zIndex: 10000, backgroundColor:'rgba(0,0,0,0.5)'}}
                onClick={(e) => {
                  if (e.target === e.currentTarget) {
                    setShowTestimonialWidget(false);
                  }
                }}
              >
                <IframeResizer 
                  src="https://embed-v2.testimonial.to/c/map-this?theme=dark" 
                  style={{ position: 'absolute', top: '15%', left: '25%', width: '50%', height: '50%', border: 'none' }} 
                />
              </div>
            )}

            {false && (
              <TestimonialPopupModal onStartClick={() => setShowTestimonialWidget(true)} />
            )}

            {(showTileGallery && !showFileUpload && !isMindMapReady) && (
              <TileGallery 
                onPdfSelect={handleMindMapUpdate} 
                onTxtSelect={handleMindMapUpdate}
                onNewTileClick={openModal} 
                isFirstMindMap={isFirstMindMap}
                isPaidUser={isPaidUser}
                isFreeOrPayPerUse={isFreeOrPayPerUse}
                cacheReset={cacheReset}
              />
            )}

            {isLoading && (
              <div className='centered-content'>
                <div className="loader">
                  <HamsterLoader />
                  <p style={{paddingTop:'20px'}}>Do not refresh</p>
                </div>
              </div>
            )}

            {showErrorScreen && (
              <div className="error-overlay">
                <div className="error-modal">
                  <p>We're sorry, something went wrong. Please try again.</p>
                  <button onClick={handleRetry} className="retry-button">Retry</button>
                </div>
              </div>
            )}

            {isMindMapReady && (
              <div 
                className="main-content" 
                style={!isReactFlow ? { 
                  backgroundImage: 'url("/backgroundPattern.png")', 
                  backgroundSize: 'contain', 
                  backgroundRepeat: 'repeat' 
                } : {}} 
                onWheel={handleWheel}
              >
                {showConfetti && ( 
                  <Confetti
                    numberOfPieces={2000}
                    tweenDuration={10000}
                    recycle={false}
                  />
                )}

                {isSummaryModalOpen && (
                  <div className="node-summary-modal">
                    <SummaryEditor
                      key={`${activeSummary.nodeText}-${activeSummary.x}-${activeSummary.y}`}
                      activeSummary={activeSummary}
                      onUpdateSummary={handleUpdateSummary}
                      setActiveSummary={setActiveSummary}
                      handleCloseSummary={handleCloseSummary}
                      fileKey={'private/' + identityId + '/' + fileKey}
                      isPaidUser={isPaidUser}
                      identityId={identityId}  
                      isSummaryLoading={isSummaryLoading}
                    />
                  </div>
                )}

                {showMindmapHeader && (
                  <MermaidControlsHeader
                    showMindmapHeader={showMindmapHeader}
                    uploadedFileName={uploadedFileName}
                    userId={userID}
                    fileKey={fileKey}
                    identityId={identityId}
                    isReactFlow={isReactFlow}
                    languageDropdownVisible={languageDropdownVisible}
                    selectedLanguage={selectedLanguage}
                    languages={languages}
                    isShareLoading={isShareLoading}
                    newMindmapButtonClicked={newMindmapButtonClicked}
                    isPaidUser={isPaidUser}
                    showUploadModalButton={true}
                    isGanttChart={isGanttChart}
                    showEditModal={showEditModal}
                    isFlowChart={isFlowChart}
                    handleMyMapsClick={handleMyMapsClick}
                    toggleLanguageDropdown={toggleLanguageDropdown}
                    setSelectedLanguage={setSelectedLanguage}
                    translateMindMap={translateMindMap}
                    handleGenerateLink={handleGenerateLink}
                    openModal={openModal}
                    saveAsPNG={saveAsPNG}
                    handleUpgradeNavigation={handleUpgradeNavigation}
                    openEditModal={openEditModal}
                    closeEditModal={closeEditModal}
                    onMindMapSaved={onMindMapSaved}
                    userMembershipPlan={userMembershipPlan}
                    userMonthlyLimit={userMonthlyLimit}
                    isChatPanelOpen={addLeftPadding}
                  />
                )}

                {showSharePopup && (
                  <div>
                    <ShareLinkComponent link={shareableUrl} style={myStyle} onClose={handleClose} />
                  </div>
                )}
                
                {!isGanttChart && (
                  <TransformWrapper 
                    defaultScale={0.5}
                    minScale={0.5}
                    maxScale={3}
                  >
                    <TransformComponent>
                      <div className="mindmap-centering-wrapper">
                        {isMindMap && (
                          <Mermaid key={mindMapCode ? mindMapCode.length : 0} chart={mindMapCode} />
                        )}                                
                      </div>
                    </TransformComponent>
                  </TransformWrapper>
                )}

                {isFlowChart && (
                  <TransformWrapper 
                    defaultScale={0.5}
                    minScale={0.5}
                    maxScale={5}
                  >
                    <TransformComponent>
                      <div className="flowchart-container">
                        <Mermaid key={mindMapCode ? mindMapCode.length : 0} chart={mindMapCode} />
                      </div>
                    </TransformComponent>
                  </TransformWrapper>
                )}

                {isGanttChart && (
                  <div className="gantt-container">
                    <MermaidGantt key={mindMapCode ? mindMapCode.length : 0} chart={mindMapCode} />
                  </div>
                )}

                {isReactFlow && (
                  <div className="react-flow-container">
                    <ReactFlowProvider>
                      <ReactFlowChart 
                        initialJson={mindMapCode} 
                        fileName={`private/${identityId}/${fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt')}`}
                      />
                    </ReactFlowProvider>        
                  </div>
                )}

                {isFlowChart && showEditModal && (
                  <FlowchartEditModal
                    isOpen={showEditModal}
                    onClose={closeEditModal}
                    fileKey={fileKey}
                    membershipPlan={userMembershipPlan}
                    onMindMapSaved={onMindMapSaved}
                    setMindMapCode={setMindMapCode}
                    forceUpdate={() => {
                      const currentContent = mindMapCode;
                      setMindMapCode(''); 
                      setTimeout(() => setMindMapCode(currentContent), 10);
                    }}
                  />
                )}

                <p className='disclaimer'>Map-This can make mistakes. Consider checking important information.</p>
              </div>
            )}
              <Toaster />

            {isModalOpen && (
              <UploadModal 
                closeModal={closeModal} 
                onMapText={handleTextUpload} 
                onMapAI={handleAIMagicUpload} 
                handleFileUploadSuccess={handleFileUploadSuccess}
                handleCreditsNeededChange={handleCreditsNeededChange} 
                isFirstMindMap={isFirstMindMap}
                userID={userID}
              />
            )}

            {errorMessage && (
              <div className="error-message">
                {errorMessage}
              </div>
            )}
            {isMindMapReady && isChatPanelOpen && !isReactFlow && (
              <div className="fixed right-0 top-0">
                <ChatPanel 
                  pdfName={`private/${identityId}/${fileKey}`}
                  onSuccessfulEdit={handleSuccessfulEdit}
                  isPDF={isPDF(`private/${identityId}/${fileKey}`)}
                  onToggle={(isOpen) => {
                    // Optional: Handle panel state in parent
                    setAddLeftPadding(isOpen);
                    //setIsChatPanelOpen(isOpen);
                  }} 
                  isOpen={isChatPanelOpen}  // Add this prop
                  isFlowChart={isFlowChart}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )}
  
  </Authenticator>
);
}

export default PDFToMindMap;