/* global Rewardful */

import { loadStripe } from '@stripe/stripe-js';
import getUserData from '../userManagement/getUserData';

const stripePromise = loadStripe('pk_live_51OJn7eGPqEG8TQso0zfoGWpGdFpsJ5IOw1f7FAEHIQwdfK5uFnxmdMvtaxcmbmVbZ5J6KYetwJwCnm8JkxTsPfd600d9j7ZsNO');
const testStripePromise = loadStripe('pk_test_51OJn7eGPqEG8TQsofkujN7Mz1bbsNGLHBqWUDO1iayILJeEWMKXr3OPDgVpZbJUL3mXKQZ4nKfx2RRh8nK9zUN5L00ezkF6YDc');

// Helper function to determine the appropriate endpoint
const getApiEndpoint = (priceId) => {
    const isLocalhost = window.location.hostname === 'localhost';
    if (isLocalhost) {
        return (priceId.includes('buyCredits'))
            ? 'https://erdbd7v3sg.execute-api.us-east-1.amazonaws.com/localhost' 
            : 'https://t1suv14mv4.execute-api.us-east-1.amazonaws.com/dev';
    }
    return (priceId.includes('buyCredits'))
        ? 'https://cq37aolpde.execute-api.us-east-1.amazonaws.com/dev' 
        : 'https://r1lasn6ud1.execute-api.us-east-1.amazonaws.com/dev';
};

// Pass the priceId so that lambda can handle which price to use
export const handleUpgradeClick = async (priceId) => {
    try {
        const info = await getUserData();
        if (!info) {
            throw new Error('User not found');
        }

        const userData = {
            Email: info.Email, // Ensure email attribute exists
            userID: info.userID // Typically the user ID
        };

        // Retrieve Rewardful referral ID if Rewardful is defined
        const referralId = typeof Rewardful !== 'undefined' ? Rewardful.referral : null;

        // Log referral ID or indicate it's not present
        if (referralId) {
            console.log("Rewardful Referral ID:", referralId);
        } else {
            console.log("No Rewardful referral ID present.");
        }

        const stripe = await stripePromise;
        const requestBody = { 
            email: userData.Email,
            cognitoUserId: userData.userID,
            priceId, // Include the priceId parameter
            ...(referralId && { referralId }) // Conditionally add referralId if it exists
        };

        const apiEndpoint = getApiEndpoint(priceId);

        const response = await fetch(apiEndpoint, { 
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ body: JSON.stringify(requestBody) }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        const { sessionId } = JSON.parse(responseData.body);
        await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
        console.error('Error redirecting to Stripe checkout:', error);
    }
};