import React, { useRef, useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { 
  Share2, 
  Plus, 
  Download, 
  Languages, 
  Loader2,
  Home,
  Check,
  X,
  Edit
} from 'lucide-react';
import { handleFileRename } from '../util/fileRename';
import toast from 'react-hot-toast';
import MindmapRatings from '../util/MindmapRatings';
import { maxWidth } from '@mui/system';


const DROPDOWN_CLOSE_DELAY = 300; // 300ms delay before closing

const MermaidControlsHeader = ({
  showMindmapHeader = true,
  uploadedFileName = '',
  userId,
  fileKey,
  identityId,
  isReactFlow,
  languageDropdownVisible = false,
  selectedLanguage = 'en',
  languages = [],
  isShareLoading = false,
  newMindmapButtonClicked = false,
  isPaidUser = false,
  showUploadModalButton = true,
  isGanttChart = false,
  isFlowChart,
  handleMyMapsClick,
  showEditModal,
  toggleLanguageDropdown,
  setSelectedLanguage,
  translateMindMap,
  handleGenerateLink,
  openModal,
  saveAsPNG,
  userMembershipPlan,
  userMonthlyLimit,
  handleUpgradeNavigation,
  onRename,
  isChatPanelOpen,
  openEditModal
}) => {
  const dropdownRef = useRef(null);
  const exportRef = useRef(null);
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const closeTimeoutRef = useRef(null);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newFileName, setNewFileName] = useState('');
  const [displayedFileName, setDisplayedFileName] = useState(uploadedFileName);
  const fileNameInputRef = useRef(null);

  const [headerPosition, setHeaderPosition] = useState(0);
  const [headerWidth, setHeaderWidth] = useState('100%');


  useEffect(() => {
    if (isChatPanelOpen) {
      setHeaderPosition(384);
      setHeaderWidth('calc(100% - 384px)');
    } else {
      setHeaderPosition(0);
      setHeaderWidth('100%');
    }
  }, [isChatPanelOpen]);
  

  const styles = {
    headerContainer: {
            position: 'fixed',
            top: 0,
            padding: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            zIndex: 40,
            transition: 'all 0.3s ease',
            width: '100%',
    },
    leftSection: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      backgroundColor: '#ffffff',
      padding: '8px 16px',
      borderRadius: '12px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      maxWidth:'500px',
      marginLeft:'20px'
    },
    rightSection: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      backgroundColor: '#ffffff',
      padding: '8px 16px',
      borderRadius: '12px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    logo: {
      width: '24px',
      height: '24px',
      marginRight: '4px'
    },
    homeButton: {
      padding: '8px',
      borderRadius: '8px',
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      transition: 'background-color 0.2s'
    },
    fileName: {
      color: '#374151',
      fontWeight: 500,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '400px',
      cursor: 'pointer',
      padding: '4px 8px',
      borderRadius: '4px',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: '#f3f4f6'
      }
    },
    fileNameInput: {
      color: '#374151',
      fontWeight: 500,
      width: '100%',
      maxWidth: '400px',
      padding: '4px 8px',
      borderRadius: '4px',
      border: '1px solid #3b82f6',
      outline: 'none',
      fontSize: 'inherit',
      fontFamily: 'inherit'
    },
    renameActions: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      marginLeft: '8px'
    },
    renameButton: {
      padding: '4px',
      borderRadius: '4px',
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#374151',
      '&:hover': {
        backgroundColor: '#f3f4f6'
      }
    },
    iconButton: {
      padding: '8px',
      borderRadius: '8px',
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background-color 0.2s',
      color: '#374151'
    },
    icon: {
      width: '20px',
      height: '20px'
    },
    divider: {
      width: '1px',
      height: '24px',
      backgroundColor: '#e5e7eb',
      margin: '0 8px'
    },
    dropdown: {
      position: 'absolute',
      right: 0,
      top: '48px',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
      width: '192px',
      padding: '12px',
      zIndex: 51
    },
    select: {
      width: '100%',
      padding: '8px',
      borderRadius: '6px',
      border: '1px solid #e5e7eb',
      marginBottom: '8px',
      outline: 'none'
    },
    translateButton: {
      width: '100%',
      padding: '8px 16px',
      backgroundColor: '#3b82f6',
      color: 'white',
      border: 'none',
      borderRadius: '6px',
      fontWeight: 500,
      cursor: 'pointer'
    },
    exportDropdown: {
      position: 'absolute',
      right: 0,
      top: '36px',
      padding: '12px 0 8px 0',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
      width: '192px',
      padding: '8px 0',
      zIndex: 51
    },
    exportButton: {
      width: '100%',
      padding: '8px 16px',
      textAlign: 'left',
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    proLabel: {
      padding: '2px 6px',
      fontSize: '12px',
      backgroundColor: '#e0f2fe',
      color: '#1d4ed8',
      borderRadius: '4px'
    },
    disabledButton: {
      opacity: 0.5,
      cursor: 'not-allowed'
    }
  };

  const handleStartRename = () => {
    setIsRenaming(true);
    setNewFileName(displayedFileName.replace(/\.[^/.]+$/, '')); // Remove file extension
    setTimeout(() => {
      fileNameInputRef.current?.focus();
      fileNameInputRef.current?.select();
    }, 0);
  };

  const handleRenameConfirm = async () => {
    if (!newFileName.trim()) {
      toast.error('File name cannot be empty');
      return;
    }

    const fileExtension = uploadedFileName.match(/\.[^/.]+$/)?.[0] || '';
    const fullNewFileName = `${newFileName.trim()}${fileExtension}`;

    const result = await handleFileRename(
      fileKey,
      newFileName.trim(),
      (newKey) => {
        toast.success('File renamed successfully');
        setDisplayedFileName(fullNewFileName);
        if (onRename) {
          onRename(newKey, fullNewFileName);
        }
      },
      (error) => {
        toast.error('Error renaming file');
        //console.error('Rename error:', error);
      }
    );

    setIsRenaming(false);
    
    if (result.success) {
      setNewFileName('');
    }
  };

  const handleRenameCancel = () => {
    setIsRenaming(false);
    setNewFileName('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleRenameConfirm();
    } else if (e.key === 'Escape') {
      handleRenameCancel();
    }
  };

  const handleInputBlur = (e) => {
    const isClickOnAction = e.relatedTarget?.closest('.rename-actions');
    if (!isClickOnAction) {
      handleRenameCancel();
    }
  };

  React.useEffect(() => {
    setDisplayedFileName(uploadedFileName);
  }, [uploadedFileName]);

  const handleExportMouseEnter = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    setShowExportDropdown(true);
  };

  const handleExportMouseLeave = () => {
    closeTimeoutRef.current = setTimeout(() => {
      setShowExportDropdown(false);
    }, DROPDOWN_CLOSE_DELAY);
  };

  // Cleanup timeout on unmount
  React.useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  if (!showMindmapHeader) return null;

  const headerStyle = {
    position: 'fixed',
    top: 0,
    left: isChatPanelOpen ? '384px' : '0',
    right: '0',
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 40,
    transition: 'left 0.3s ease-in-out',
    
  };


  return (
    <>
      <div style={headerStyle}>
        {/* Left Section */}
        <div style={styles.leftSection}>
          <div style={styles.logoContainer}>
            <img src="/logo-no-name.png" alt="Logo" style={styles.logo} />
            <button
              onClick={handleMyMapsClick}
              style={styles.homeButton}
              data-tooltip-id="header-tooltip"
              data-tooltip-content="My Maps Dashboard"
            >
              <Home style={styles.icon} />
            </button>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isRenaming ? (
              <>
                <input
                  ref={fileNameInputRef}
                  type="text"
                  value={newFileName}
                  onChange={(e) => setNewFileName(e.target.value)}
                  onKeyDown={handleKeyDown}
                  style={styles.fileNameInput}
                />
                <div style={styles.renameActions}>
                  <button
                    onClick={handleRenameConfirm}
                    style={styles.renameButton}
                    data-tooltip-id="header-tooltip"
                    data-tooltip-content="Confirm"
                  >
                    <Check style={{ width: '16px', height: '16px' }} />
                  </button>
                  <button
                    onClick={handleRenameCancel}
                    style={styles.renameButton}
                    data-tooltip-id="header-tooltip"
                    data-tooltip-content="Cancel"
                  >
                    <X style={{ width: '16px', height: '16px' }} />
                  </button>
                </div>
              </>
            ) : (
              <span
                onClick={handleStartRename}
                style={styles.fileName}
                data-tooltip-id="header-tooltip"
                data-tooltip-content="Click to rename"
              >
                {displayedFileName}
              </span>
            )}
          </div>
        </div>

        {/* Right Section */}
        <div style={styles.rightSection}>
            <>
            <MindmapRatings userId={userId} fileKey={fileKey} identityId={identityId} />
              <button
                onClick={openModal}
                style={{
                  ...styles.iconButton,
                  backgroundColor: newMindmapButtonClicked ? '#f3f4f6' : 'transparent'
                }}
                data-tooltip-id="header-tooltip"
                data-tooltip-content="Create New Map"
              >
                <Plus style={styles.icon} />
              </button>



              {false &&(
                            <button
                              onClick={openEditModal}
                              style={styles.iconButton}
                              data-tooltip-id="header-tooltip"
                              data-tooltip-content="Edit Map"
                            >
                              <Edit style={styles.icon} />
                            </button>
                            )}
{!isReactFlow && ( <>

              <div style={styles.divider} />
              <button
                onClick={handleGenerateLink}
                disabled={isShareLoading}
                style={{
                  ...styles.iconButton,
                  ...(isShareLoading && styles.disabledButton)
                }}
                data-tooltip-id="header-tooltip"
                data-tooltip-content="Share Map"
              >
                {isShareLoading ? (
                  <Loader2 style={{...styles.icon, animation: 'spin 1s linear infinite'}} />
                ) : (
                  <Share2 style={styles.icon} />
                )}
              </button>
              </> )}
              {!isReactFlow && ( <>        
              <div style={styles.divider} />

              <div style={{ position: 'relative' }}>
                <button
                  onClick={() => saveAsPNG(isPaidUser)}
                  onMouseEnter={handleExportMouseEnter}
                  onMouseLeave={handleExportMouseLeave}
                  style={styles.iconButton}
                  data-tooltip-id="header-tooltip"
                  data-tooltip-content="Export Map"
                >
                  <Download style={styles.icon} />
                </button>


                {!isPaidUser && showExportDropdown && (
                  <div
                    ref={exportRef}
                    style={styles.exportDropdown}
                    onMouseEnter={handleExportMouseEnter}
                    onMouseLeave={handleExportMouseLeave}
                  >
                    <button
                      onClick={() => saveAsPNG(false)}
                      style={styles.exportButton}
                    >
                      Export with Watermark
                    </button>
                    <button
                      onClick={(e) => handleUpgradeNavigation(e, 'yearly')}
                      style={styles.exportButton}
                    >
                      <span>Export without Watermark</span>
                      <span style={styles.proLabel}>PRO</span>
                    </button>
                  </div>
                )}
              </div>
              </>
                )}

  
{!isReactFlow && ( <> 

        {/** 
         *   <div style={styles.divider} />
              <div style={{ position: 'relative' }}>
                <button
                  onClick={toggleLanguageDropdown}
                  style={styles.iconButton}
                  data-tooltip-id="header-tooltip"
                  data-tooltip-content="Translate Map"
                >
                  <Languages style={styles.icon} />
                </button>

                {languageDropdownVisible && (
                  <div ref={dropdownRef} style={styles.dropdown}>
                    <select
                      value={selectedLanguage}
                      onChange={(e) => setSelectedLanguage(e.target.value)}
                      style={styles.select}
                    >
                      {languages.map((language) => (
                        <option key={language.code} value={language.code}>
                          {language.name}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={translateMindMap}
                      style={styles.translateButton}
                      className="translate-button"
                    >
                      Translate
                    </button>
                  </div>
                )}
              </div>
              */}
              </>)}
            </>

        </div>
      </div>

      <Tooltip id="header-tooltip" style={{ zIndex: 100 }} />

      <style>
        {`
          @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
          
          button:hover {
            background-color: #f3f4f6 !important;
          }
          
          button.translate-button:hover {
            background-color: #2563eb !important;
          }
          
          select:focus {
            border-color: #3b82f6;
            box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
          }

          input:focus {
            border-color: #3b82f6;
            box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
          }

          .tooltip {
            z-index: 100;
          }
        `}
      </style>
    </>
  );
};

export default MermaidControlsHeader;