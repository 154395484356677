import AWS from 'aws-sdk';
import { fetchAuthSession } from '@aws-amplify/auth';

const updateUserOnboardingData = async (userOnboardingData, isUserOnboarded, emailSignup, referralInfo) => {
    try {
        const session = await fetchAuthSession();

        if (session && session.credentials) {
            AWS.config.update({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: session.credentials.accessKeyId,
                    secretAccessKey: session.credentials.secretAccessKey,
                    sessionToken: session.credentials.sessionToken
                }
            });

            const dynamoDb = new AWS.DynamoDB.DocumentClient();
            const tableName = 'pdftomindmapUsers';
            const userId = session.userSub;
            
            let updateExpression = 'set userOnboardingData = :onboardingData, isUserOnboarded = :isUserOnboarded, emailSignup = :emailSignup';
            let expressionAttributeValues = {
                ':onboardingData': userOnboardingData,
                ':isUserOnboarded': isUserOnboarded,
                ':emailSignup': emailSignup
            };

            // Add referral information if provided, storing it directly as an object
            if (referralInfo) {
                updateExpression += ', referralInfo = :referralInfo';
                // Store referralInfo directly as an object, not as an array
                expressionAttributeValues[':referralInfo'] = {
                    fullUrl: referralInfo.fullUrl,
                    referralCode: referralInfo.referralCode,
                    timestamp: referralInfo.timestamp
                };
            }

            const updateParams = {
                TableName: tableName,
                Key: { 'userID': userId },
                UpdateExpression: updateExpression,
                ExpressionAttributeValues: expressionAttributeValues,
                ReturnValues: 'UPDATED_NEW'
            };

            // Update the user onboarding data
            const updateResult = await dynamoDb.update(updateParams).promise();
            return updateResult;
        } else {
            console.error("Session credentials are missing.");
            throw new Error("Session credentials are missing.");
        }
    } catch (error) {
        console.error('Error updating user onboarding data in DynamoDB:', error);
        throw error;
    }
};

export default updateUserOnboardingData;