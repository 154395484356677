import React, { useEffect, useState, useRef } from 'react';
import { downloadData, uploadData } from 'aws-amplify/storage';
import { FaTimesCircle, FaPlusCircle, FaTrashAlt, FaIcons } from 'react-icons/fa';
import mermaid from 'mermaid';
import FontAwesomeIconDropdown from './FontAwesomeIconDropdown';
import './FlowchartEditModal.css';

// ============================================================================
// Constants
// ============================================================================
const VERTEX_TYPE = {
    ROUND: 'round',
    STADIUM: 'stadium',
    DOUBLECIRCLE: 'doublecircle',
    CIRCLE: 'circle',
    DIAMOND: 'diamond'
};

const CONTAINER_STYLE_PROPERTY = {
    FILL: 'fill',
    STROKE: 'stroke',
    STROKE_WIDTH: 'stroke-width',
    STROKE_DASHARRAY: 'stroke-dasharray'
};

const LABEL_STYLE_PROPERTY = {
    COLOR: 'color'
};


// ============================================================================
// Text Formatting Functions
// ============================================================================
const formatNodeText = {
    // Remove HTML tags and line breaks for display
    stripForDisplay: (text) => {
        if (!text) return '';
        
        // Remove HTML tags
        let processedText = text.replace(/<[^>]+>/g, '');
        
        // Remove line breaks
        processedText = processedText.replace(/\r?\n/g, ' ');
        
        // Decode HTML entities
        processedText = processedText.replace(/&nbsp;/g, ' ')
                                   .replace(/&amp;/g, '&')
                                   .replace(/&lt;/g, '<')
                                   .replace(/&gt;/g, '>')
                                   .replace(/&quot;/g, '"')
                                   .replace(/&#39;/g, "'");
        
        return processedText;
    },

    // Preserve HTML formatting when saving to node object
    preserveForStorage: (text) => {
        if (!text) return '';
        
        // Preserve line breaks as actual line breaks in the stored text
        let processedText = text.replace(/\r?\n/g, '\n');
        
        // Escape HTML entities to prevent XSS
        processedText = processedText.replace(/&/g, '&amp;')
                                   .replace(/</g, '&lt;')
                                   .replace(/>/g, '&gt;')
                                   .replace(/"/g, '&quot;')
                                   .replace(/'/g, '&#39;');
        
        return processedText;
    }
};




// ============================================================================
// Utility Functions
// ============================================================================

const entityCodesToText = (input) => {
    if (!input) return '';
    input = input.replace(/#(\d+);/g, "&#$1;")
                 .replace(/#([a-z]+);/g, "&$1;");
    const element = document.createElement("textarea");
    element.innerHTML = input;
    return element.value;
};

const getTransformAttr = (el) => {
    const transformAttr = el.getAttribute("transform");
    const translateMatch = transformAttr?.match(/translate\(([ \d.-]+),\s*([\d.-]+)\)/);
    let transformX = 0;
    let transformY = 0;
    if (translateMatch) {
        transformX = Number(translateMatch[1]);
        transformY = Number(translateMatch[2]);
    }
    return { transformX, transformY };
};

// Add this function inside your component
const autoResizeTextArea = (element) => {
    if (!element) return;
    
    // Reset height temporarily to get the correct scrollHeight
    element.style.height = 'auto';
    
    // Add a small buffer to prevent flickering
    const buffer = 2;
    
    // Get the scroll height and add a small buffer
    const scrollHeight = element.scrollHeight;
    
    // Set the height to scrollHeight
    element.style.height = `${scrollHeight + buffer}px`;
};


// ============================================================================
// Main Component
// ============================================================================
const FlowchartEditModal = ({ 
    isOpen, 
    onClose, 
    fileKey, 
    onMindMapSaved, 
    membershipPlan, 
    setMindMapCode, 
    forceUpdate 
}) => {
    // =========================================================================
    // State Management
    // =========================================================================
    const [originalContent, setOriginalContent] = useState('');
    const [nodes, setNodes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [autosaveStatus, setAutosaveStatus] = useState('All changes saved');
    const [activeIconNode, setActiveIconNode] = useState(null);

    // =========================================================================
    // Refs
    // =========================================================================
    const modalRef = useRef();
    const saveTimeoutRef = useRef(null);
    const diagramContainerRef = useRef(null);

    // =========================================================================
    // Initialize Mermaid
    // =========================================================================
    useEffect(() => {
        mermaid.initialize({
            startOnLoad: true,
            securityLevel: 'loose',
            flowchart: {
                curve: 'basis',
                useMaxWidth: false,
                htmlLabels: true
            },
            maxTextSize: 50000
        });
    }, []);

    // =========================================================================
    // Event Handlers
    // =========================================================================
    const handleOnClose = () => {
        forceUpdate();
        onClose();
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            handleOnClose();
        }
    };

    // Check if a node is a root node by checking if it starts with "Start(("
    const isRootNode = (nodeId) => {
        const node = nodes.find(n => n.id === nodeId);
        if (!node) return false;
        const fullNodeDef = node.beforeText + node.originalText + node.afterText;
        return fullNodeDef.trim().startsWith('Start((');
    };

    const handleNodeTextChange = (nodeId, newText) => {
        setNodes(prevNodes => 
            prevNodes.map(node => {
                if (node.id === nodeId) {
                    const iconPrefix = node.icon ? `fa:${node.icon} ` : '';
                    // Remove any existing icon prefix from the display text
                    const cleanText = newText.replace(/fa:fa-[^\s]+\s*/g, '');
                    return {
                        ...node,
                        text: cleanText, // Store clean text without icon prefix
                        originalText: iconPrefix + cleanText // Keep icon prefix in originalText
                    };
                }
                return node;
            })
        );
    
        // Find and resize the textarea
        const textarea = document.querySelector(`textarea[data-node-id="${nodeId}"]`);
        if (textarea) {
            autoResizeTextArea(textarea);
        }
        setAutosaveStatus('Unsaved changes');
    };

    const handleIconChange = (nodeId, icon) => {
        setNodes(prevNodes => 
            prevNodes.map(node => {
                if (node.id === nodeId) {
                    const iconName = icon ? `fa-${icon.value.replace('fa fa-', '')}` : null;
                    const cleanText = node.text.replace(/fa:fa-[^\s]+\s*/, '').trim();
                    const newText = iconName ? `fa:${iconName} ${cleanText}` : cleanText;
                    
                    return {
                        ...node,
                        icon: iconName,
                        text: cleanText,
                        originalText: newText
                    };
                }
                return node;
            })
        );
        setActiveIconNode(null);
        setAutosaveStatus('Unsaved changes');
        
        if (saveTimeoutRef.current) {
            clearTimeout(saveTimeoutRef.current);
        }
        saveTimeoutRef.current = setTimeout(() => {
            handleAutoSave();
        }, 1000);
    };

    const handleAddChildNode = (parentId) => {
        const parent = nodes.find(node => node.id === parentId);
        if (!parent) {
            //console.error(`Parent node with ID ${parentId} not found.`);
            return;
        }

        const generateUniqueNodeId = () => {
            const timestamp = Date.now();
            const randomSuffix = Math.floor(Math.random() * 1000);
            return `node_${timestamp}_${randomSuffix}`;
        };

        const newNodeId = generateUniqueNodeId();
        const newNode = {
            id: newNodeId,
            text: "",
            icon: '',
            type: "rectangle",
            line: parent.line + 1,
            beforeText: `    ${parentId} --> ${newNodeId}[\"`,
            afterText: "\"]",
            hasQuotes: true,
            containerStyle: {
                fill: '#e6e6e6',
                stroke: '#999',
                'stroke-width': '1px'
            },
            labelStyle: {
                color: 'black'
            },
            level: parent.level + 1,
            children: []
        };

        setNodes(prevNodes => {
            const parentIndex = prevNodes.findIndex(n => n.id === parentId);
            const insertIndex = parentIndex + 1;
            const before = prevNodes.slice(0, insertIndex);
            const after = prevNodes.slice(insertIndex);
            return [...before, newNode, ...after];
        });

        const contentLines = originalContent.split("\n");
        const parentIndex = contentLines.findIndex(line => line.includes(`${parentId}`));
        if (parentIndex !== -1) {
            const newConnection = `    ${parentId} --> ${newNodeId}[""]`;
            contentLines.splice(parentIndex + 1, 0, newConnection);
        } else {
            contentLines.push(`    ${parentId} --> ${newNodeId}[""]`);
        }

        const updatedContent = contentLines.join("\n");
        setOriginalContent(updatedContent);
        setAutosaveStatus("Unsaved changes");
    };

    const handleDeleteNode = async (nodeId) => {
        // Prevent deletion if it's a root node
        if (isRootNode(nodeId)) {
            console.warn('Cannot delete root node');
            return;
        }

        setAutosaveStatus('Deleting...');
        setLoading(true);
        
        try {
            const deleteNodeAndChildren = (id) => {
                const node = nodes.find(n => n.id === id);
                if (!node) return [];
                
                const childIds = node.children.reduce((acc, childId) => {
                    return [...acc, childId, ...deleteNodeAndChildren(childId)];
                }, []);
                
                return [id, ...childIds];
            };
            
            const idsToDelete = deleteNodeAndChildren(nodeId);
            
            const lines = originalContent.split('\n');
            const updatedLines = lines.filter(line => {
                const trimmedLine = line.trim();
                return !idsToDelete.some(id => 
                    trimmedLine.includes(id) || 
                    trimmedLine.startsWith(`style ${id}`) ||
                    trimmedLine.startsWith(`class ${id}`)
                );
            });
            
            const updatedContent = updatedLines.join('\n');
            
            setNodes(prevNodes => 
                prevNodes.filter(node => !idsToDelete.includes(node.id))
            );
            
            const file = new File([updatedContent], fileKey, { type: 'text/plain' });
            await uploadData({
                key: `${fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt')}`,
                data: file,
                options: { accessLevel: 'private' }
            });
            
            setOriginalContent(updatedContent);
            setMindMapCode(updatedContent);
            await renderMermaidDiagram(updatedContent);
            onMindMapSaved();
            setAutosaveStatus('All changes saved');
            
        } catch (error) {
            //console.error('Error deleting node:', error);
            setAutosaveStatus('Error deleting node');
        } finally {
            setLoading(false);
        }
    };

    // =========================================================================
    // File Operations
    // =========================================================================
    const loadFileContent = async () => {
        setLoading(true);
        try {
            const downloadResult = await downloadData({
                key: `${fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt')}`,
                options: { accessLevel: 'private' }
            }).result;
            const textContent = await downloadResult.body.text();
            
            setOriginalContent(textContent);
            parseNodes(textContent);
        } catch (error) {
            //console.error('Error loading file:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAutoSave = async () => {
        setAutosaveStatus('Saving...');
        setLoading(true);
        
        try {
            const lines = originalContent.split('\n');
            const contentLines = lines.slice(1);
            
            let updatedContent = lines[0] + '\n';
            
            const styleLines = [];
            const nodeLines = [];
            
            contentLines.forEach(line => {
                const trimmedLine = line.trim();
                if (trimmedLine.startsWith('style ') || 
                    trimmedLine.startsWith('classDef ') || 
                    trimmedLine.startsWith('class ')) {
                    styleLines.push(line);
                } else if (trimmedLine) {
                    nodeLines.push(line);
                }
            });
    
            nodes.forEach(node => {
                if (node.beforeText.includes('-->')) {
                    const connection = node.beforeText.trim();
                    // Encode line breaks before saving
                    const encodedText = node.originalText.replace(/\n/g, '\\n');
                    updatedContent += `${connection}${encodedText}${node.afterText}\n`;
                } else {
                    const bracketMatches = node.beforeText.match(/(\[|\(|\{+)/);
                    const bracket = bracketMatches ? bracketMatches[1] : '[';
                    const closingBracket = {
                        '[': ']',
                        '(': ')',
                        '{': '}'
                    }[bracket] || ']';
                    
                    // Encode line breaks and handle quotes properly
                    const encodedText = node.originalText.replace(/\n/g, '\\n');
                    const textWithQuotes = node.hasQuotes ? `"${encodedText}"` : encodedText;
                    updatedContent += `${node.id}${bracket}${textWithQuotes}${closingBracket}\n`;
                }
            });
    
            styleLines.forEach(line => {
                updatedContent += `${line}\n`;
            });
    
            const file = new File([updatedContent], fileKey, { type: 'text/plain' });
            await uploadData({
                key: `${fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt')}`,
                data: file,
                options: { accessLevel: 'private' }
            });
    
            setOriginalContent(updatedContent);
            setMindMapCode(updatedContent);
            await renderMermaidDiagram(updatedContent);
            onMindMapSaved();
            setAutosaveStatus('All changes saved');
        } catch (error) {
            //console.error('Error during auto-save:', error);
            setAutosaveStatus('Error saving');
        } finally {
            setLoading(false);
        }
    };

    // =========================================================================
    // Node Parsing and Diagram Rendering
    // =========================================================================
    const parseNodes = (content) => {
        try {
            const styleMap = {};
            const styleRegex = /style\s+(\w+)\s+([^\\n]+)/g;
            let styleMatch;
            
            while ((styleMatch = styleRegex.exec(content)) !== null) {
                const [_, nodeId, styles] = styleMatch;
                const styleObj = {};
                styles.split(',').forEach(style => {
                    const [key, value] = style.split(':').map(s => s.trim());
                    styleObj[key] = value;
                });
                styleMap[nodeId] = styleObj;
            }
    
            const lines = content.split('\n');
            const nodeMap = new Map();
            const connections = new Map();
            
            lines.forEach((line, lineIndex) => {
                const trimmedLine = line.trim();
                if (!trimmedLine || trimmedLine.toLowerCase().startsWith('flowchart')) {
                    return;
                }
    
                const connectionMatch = trimmedLine.match(/(\w+)\s*-->\s*(\w+)/);
                if (connectionMatch) {
                    const [_, parentId, childId] = connectionMatch;
                    if (!connections.has(parentId)) {
                        connections.set(parentId, new Set());
                    }
                    connections.get(parentId).add(childId);
                }
    
                const nodeRegex = /(\w+)\s*(?:\(+|\[+|\{+)(?:"([^"]+)"|'([^']+)'|([^)\]}\n]+))(?:\)+|\]+|\}+)/;
                const match = trimmedLine.match(nodeRegex);
    
                if (match) {
                    const [fullMatch, id, doubleQuoted, singleQuoted, unquoted] = match;
                    // Decode the text content to handle line breaks properly
                    const encodedText = doubleQuoted || singleQuoted || unquoted;
                    const text = encodedText ? entityCodesToText(encodedText).replace(/\\n/g, '\n') : '';
                    const startIndex = line.indexOf(fullMatch);
                    const textStartIndex = line.indexOf(encodedText, startIndex);

                    let type = 'default';
                    if (line.includes('((') && line.includes('))')) {
                        type = 'circle';
                    } else if (line.includes('{{') && line.includes('}}')) {
                        type = 'hexagon';
                    } else if (line.includes('[') && line.includes(']')) {
                        type = 'rectangle';
                    } else if (line.includes('(') && line.includes(')')) {
                        type = 'round';
                    } else if (line.includes('{') && line.includes('}')) {
                        type = 'rhombus';
                    }
                    
                    const iconMatch = text.match(/fa:fa-([^\s]+)/);
                    const icon = iconMatch ? `fa-${iconMatch[1]}` : null;
                    const cleanText = text.replace(/fa:fa-[^\s]+\s*/, '').trim();
                    
                    const nodeInfo = {
                        id,
                        text: cleanText, // Store clean text without icon prefix
                        originalText: text, // Keep original text with icon
                        type,
                        icon: icon,                    
                        line: lineIndex,
                        beforeText: line.substring(0, textStartIndex),
                        afterText: line.substring(textStartIndex + text.length),
                        hasQuotes: Boolean(doubleQuoted || singleQuoted),
                        containerStyle: styleMap[id] ? {
                            fill: styleMap[id].fill || '#fff',
                            stroke: styleMap[id].stroke || '#333',
                            'stroke-width': styleMap[id]['stroke-width'] || '1px',
                        } : {
                            fill: '#e6e6e6',
                            stroke: '#999',
                            'stroke-width': '1px'
                        },
                        labelStyle: {
                            color: styleMap[id]?.color || 'black'
                        },
                        level: 0,
                        children: []
                    };
        
                    nodeMap.set(id, nodeInfo);
                }
            });

            const calculateLevels = (nodeId, level = 0, visited = new Set()) => {
                if (visited.has(nodeId)) return;
                visited.add(nodeId);

                const node = nodeMap.get(nodeId);
                if (node) {
                    node.level = level;
                    const children = connections.get(nodeId) || new Set();
                    children.forEach(childId => {
                        const childNode = nodeMap.get(childId);
                        if (childNode) {
                            node.children.push(childId);
                            calculateLevels(childId, level + 1, visited);
                        }
                    });
                }
            };

            const allNodes = new Set(nodeMap.keys());
            connections.forEach((children, _) => {
                children.forEach(childId => allNodes.delete(childId));
            });

            allNodes.forEach(rootId => calculateLevels(rootId));

            const orderedNodes = [];
            const addNodeAndChildren = (nodeId) => {
                const node = nodeMap.get(nodeId);
                if (node) {
                    orderedNodes.push(node);
                    node.children.forEach(childId => addNodeAndChildren(childId));
                }
            };

            allNodes.forEach(rootId => addNodeAndChildren(rootId));
            setNodes(orderedNodes);
        } catch (error) {
            //console.error('Error parsing flowchart:', error);
        }
    };

    const renderMermaidDiagram = async (content) => {
        if (!diagramContainerRef.current) return;
        
        try {
            let mermaidDiv = diagramContainerRef.current.querySelector('.mermaid');
            if (!mermaidDiv) {
                mermaidDiv = document.createElement('div');
                mermaidDiv.className = 'mermaid';
                diagramContainerRef.current.appendChild(mermaidDiv);
            }
            
            mermaidDiv.removeAttribute('data-processed');
            mermaidDiv.innerHTML = content;
            
            mermaid.initialize({
                startOnLoad: false,
                securityLevel: 'loose',
                flowchart: {
                    curve: 'basis',
                    useMaxWidth: false,
                    htmlLabels: true,
                    defaultRenderer: 'dagre-d3'
                },
                maxTextSize: 50000
            });
            
            await mermaid.init(undefined, mermaidDiv);
            
            const svg = mermaidDiv.querySelector('svg');
            if (svg) {
                svg.style.maxWidth = '100%';
                svg.style.height = 'auto';
                svg.setAttribute('width', '100%');
                svg.setAttribute('height', '100%');
                svg.style.minHeight = '200px';
            }
        } catch (error) {
            //console.error('Error rendering mermaid diagram:', error);
        }
    };

    // =========================================================================
    // Effects
    // =========================================================================


    useEffect(() => {
        nodes.forEach(node => {
            const textarea = document.querySelector(`textarea[data-node-id="${node.id}"]`);
            if (textarea) {
                autoResizeTextArea(textarea);
            }
        });
    }, [nodes]);

    useEffect(() => {
        if (saveTimeoutRef.current) {
            clearTimeout(saveTimeoutRef.current);
        }
    
        if (nodes.length > 0) {
            saveTimeoutRef.current = setTimeout(() => {
                handleAutoSave();
            }, 1000);
        }
    
        return () => {
            if (saveTimeoutRef.current) {
                clearTimeout(saveTimeoutRef.current);
            }
        };
    }, [nodes]);

    useEffect(() => {
        if (isOpen) {
            loadFileContent();
        }
        return () => {
            if (saveTimeoutRef.current) {
                clearTimeout(saveTimeoutRef.current);
            }
        };
    }, [isOpen, fileKey]);

    /*
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    */

    if (!isOpen) return null;

    // =========================================================================
    // Render
    // =========================================================================
    return (
        <div className="flowchart-edit-modal-overlay">
            <div className="flowchart-editing-modal" ref={modalRef}>
                <div className="flowchart-modal-header">
                    <h3>Edit Flowchart</h3>
                    <div className="modal-controls">
                        <span className={`autosave-status ${autosaveStatus}`}>
                            {autosaveStatus}
                        </span>
                        <button className="close-button-flowchart" onClick={handleOnClose}>
                            <FaTimesCircle />
                        </button>
                    </div>
                </div>

                <div className="flowchart-nodes-section">
                    {nodes.map((node) => (
                        <div 
                            key={node.id} 
                            className="node-edit-container"
                            style={{
                                marginLeft: `${node.level * 20}px`,
                                borderLeft: node.level > 0 ? '2px solid #eee' : 'none',
                                paddingLeft: node.level > 0 ? '10px' : '0'
                            }}
                        >
                            <div className="node-id">
                                <div>
                                    <span className="node-type">({node.type})</span>
                                </div>
                                <div className="node-actions">
                                    <button
                                        className="add-child-button"
                                        onClick={() => handleAddChildNode(node.id)}
                                        disabled={loading}
                                        title="Add child node"
                                    >
                                        <FaPlusCircle />
                                    </button>
                                    <button
                                        className="delete-node-button"
                                        onClick={() => handleDeleteNode(node.id)}
                                        disabled={loading || isRootNode(node.id)}
                                        title="Delete node and its children"
                                    >
                                        <FaTrashAlt />
                                    </button>
                                </div>
                            </div>

                            <div className="textarea-container">
                                <div className="icon-section">
                                    <button
                                        className="icon-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setActiveIconNode(activeIconNode === node.id ? null : node.id);
                                        }}
                                        disabled={loading}
                                        title="Select icon"
                                    >
                                        {node.icon ? (
                                            <i className={`fa ${node.icon}`} />
                                        ) : (
                                            <FaIcons />
                                        )}
                                    </button>
                                    {activeIconNode === node.id && (
                                        <div className="icon-dropdown-container">
                                            <FontAwesomeIconDropdown
                                                onIconChange={(icon) => handleIconChange(node.id, icon)}
                                                isVisible={true}
                                            />
                                        </div>
                                    )}
                                </div>
                                <textarea
                                    value={node.text}  // The text should already have proper line breaks
                                    onChange={(e) => {
                                        handleNodeTextChange(node.id, e.target.value);
                                        autoResizeTextArea(e.target);
                                    }}
                                    onInput={(e) => autoResizeTextArea(e.target)}
                                    className="node-text-input"
                                    data-node-id={node.id}  // Add this to help with resizing
                                    disabled={loading}
                                    style={{
                                        backgroundColor: node.containerStyle?.fill || 'white',
                                        color: node.labelStyle?.color || 'black',
                                        border: `1px solid ${node.containerStyle?.stroke || '#999'}`,
                                        borderWidth: node.containerStyle?.['stroke-width'] || '1px',
                                        borderStyle: node.containerStyle?.['stroke-dasharray'] ? 'dashed' : 'solid'
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FlowchartEditModal;