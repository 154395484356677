// HowCreditsWork.js
import React from 'react';
import NavBar from '../ui-components/LandingPage/Navbar';
import Footer from '../ui-components/LandingPage/Footer';
import './HowCreditsWork.css';

const HowCreditsWork = () => {
  return (
    <div className="page-container">
      <NavBar />
      
      <main className="credits-main">
        <div className="content-container">
          <header className="page-header">
            <h1>How Credits Work 🧮</h1>
            <p className="intro-text">
              Map This uses a credit system to manage the use of resources 🛠️. Here's how we calculate the number of credits needed for each mind map you create.
            </p>
          </header>

          <section className="info-section">
            <h2>Understanding Credits 💡</h2>
            <p>
              Credits are calculated based on the amount of text processed by our system. We use the "token" concept where:
            </p>
            <ul className="info-list">
              <li>1 token ≈ 4 characters of text</li>
              <li>100 tokens ≈ 75 words 📖</li>
            </ul>
          </section>

          <section className="info-section">
            <h2>Credit Calculation 📊</h2>
            <p>
              The cost in credits for creating a mind map is calculated as follows:
            </p>
            <ul className="info-list">
              <li>Each 5000 tokens cost 5 credits</li>
              <li>If it's your first ever mind map, we only deduct 5 credits, no matter the size (with reasonable limits)</li>
              <li>After your first map, the minimum credits deducted are 5 and the maximum are 50 per mind map</li>
            </ul>
          </section>

          <section className="info-section">
            <h2>Example Calculations 📝</h2>
            <p>
              Consider this sample document which contains approximately 18,750 words. Here is how the credit calculation would work:
            </p>
            <ol className="numbered-list">
              <li>
                <strong>For 18,750 words (about 25,000 tokens), it would typically cost 25 credits.</strong>
              </li>
              <li>
                If it's your first mind map, you would only pay 5 credits, regardless of the document size.
              </li>
            </ol>

            <div className="download-box">
              <p>
                Download the sample document with about 18,750 words to see the content that equates to 25 credits:
              </p>
              <a href="/samplePDF.pdf" download="SampleDocument.pdf" className="download-button">
                <svg className="download-icon" viewBox="0 0 24 24">
                  <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>
                Download Sample Document
              </a>
            </div>
          </section>

          <p className="closing-note">
            We hope this guide helps you understand how credits are managed and deducted. Use your credits wisely to maximize the value from our tool.
          </p>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default HowCreditsWork;