import React, { useState, useRef, useEffect } from 'react';
import { Maximize2, Minimize2, X, ChevronRight, ChevronLeft } from 'lucide-react';
import './ChatPanel.css';

const ChatPanel = ({ pdfName, onSuccessfulEdit, isPDF, onToggle, isReactFlow, isFlowChart }) => {
  const [mode, setMode] = useState('edit');
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const messagesEndRef = useRef(null);

  // Define API endpoints based on environment
  const getApiEndpoint = (mode) => {
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    
    if (mode === 'edit') {
      return isLocalhost
        ? 'https://u4ss4ya4nd.execute-api.us-east-1.amazonaws.com/local'
        : 'https://4av7l0148k.execute-api.us-east-1.amazonaws.com/dev';
    } else {
      return isLocalhost
        ? 'http://localhost:3001/chat'
        : 'https://4kunvjwvlvkepjzhaoqb2xhthi0awabh.lambda-url.us-east-1.on.aws/';
    }
  };

  useEffect(() => {
    if (!isPDF) {
      setMode('edit');
    }
  }, [isPDF]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleModeSwitch = (newMode) => {
    setMode(newMode);
    setMessages([]);
    setInputValue('');
    setError(null);
  };

  const togglePanel = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    if (onToggle) {
      onToggle(newIsOpen);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      const newMessage = {
        id: Date.now(),
        text: inputValue,
        sender: 'user'
      };
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setInputValue('');
      setIsLoading(true);
      setError(null);

      try {
        const endpoint = getApiEndpoint(mode);

        const requestBody = mode === 'edit' 
          ? {
              filename: pdfName,
              edit_query: inputValue,
            }
          : {
              pdfName: pdfName,
              query: inputValue,
            };

        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`API request failed with status ${response.status}`);
        }

        if (mode === 'edit') {
          onSuccessfulEdit();
          setMessages(prevMessages => [
            ...prevMessages,
            { 
              id: Date.now(), 
              text: 'Your request was successfully processed. Please see your diagram and let me know if you need any other changes.',
              sender: 'bot' 
            }
          ]);
        } else {
          // Chat mode - handle streaming
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let partialMessage = '';
          
          const botMessageId = Date.now();
          setMessages(prevMessages => [
            ...prevMessages,
            {
              id: botMessageId,
              text: '',
              sender: 'bot'
            }
          ]);

          while (true) {
            const { value, done } = await reader.read();
            if (done) break;
            
            const chunk = decoder.decode(value, { stream: true });
            partialMessage += chunk;

            setMessages(prevMessages => {
              const updatedMessages = [...prevMessages];
              const lastMessage = updatedMessages[updatedMessages.length - 1];
              if (lastMessage.id === botMessageId) {
                lastMessage.text = partialMessage;
              }
              return updatedMessages;
            });
          }
        }
      } catch (err) {
        console.error('Error in handleSubmit:', err);
        setError(`An error occurred: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div 
        className={`chat-tab ${isOpen ? 'open' : 'closed'}`}
        onClick={togglePanel}
        role="button"
        tabIndex={0}
      >
        {isOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
      </div>
      <div className={`chat-panel-wrapper ${isOpen ? 'open' : 'closed'}`}>
        {isOpen && (
          <div className="chat-container">
            <header className="panel-header">
              <h2 className="panel-title">Document Editor</h2>
              <button
                onClick={togglePanel}
                className="close-button-panel"
                aria-label="Close panel"
              >
                <X size={20} />
              </button>
            </header>
            <div className="messages-area">
              {messages.length === 0 && (
                <div className="welcome-message">
                  <h3>Welcome to the Document Editor</h3>
                  <p>
                    Describe your changes clearly and I'll help you modify the document.
                    After each change, you'll see the updates reflected in your diagram.
                  </p>
                </div>
              )}
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`message ${message.sender}`}
                >
                  {message.text}
                </div>
              ))}
              {isLoading && (
                <div className="typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              )}
              {error && (
                <div className="error-message">
                  {error}
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
            <form onSubmit={handleSubmit} className="message-form">
              <div className="input-area">
                <textarea
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    e.target.style.height = 'auto';
                    e.target.style.height = `${e.target.scrollHeight}px`;
                  }}
                  placeholder="Describe your changes..."
                  className="message-input"
                  rows="1"
                />
                <button type="submit" className="send-button">
                  Send
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatPanel;